import http from '@/utils'
import apiUrls from '@/api/apiUrls'

export const iamService = {
  fetchTocAcceptance,
  postTocAcceptance,
  fetchUserApps,
  fetchOktaUserInternal,
  fetchOktaAppRequestAccessData,
  fetchIfUserBelongsToGroup,
  assignUserToApplication,
  mfaEnabled,
  mfaSubscribed,
  mfaUnSubscribe,
  enrollUserToMfaFactor,
  resendMfaChallenge,
  activateMfaFactor
}

async function fetchTocAcceptance(requestConfig, application) {
  const url = application !== '' ? apiUrls.IAM_OUTER_TOC_ACCEPTANCE_APP.replace('{application}', application) : apiUrls.IAM_OUTER_TOC_ACCEPTANCE

  return http.get(url, requestConfig)
    .catch(() => {
      if(application !== '') return fetchTocAcceptance(requestConfig, '')
    })
}

async function postTocAcceptance(requestConfig, data) {
  return http.post(apiUrls.IAM_OUTER_TOC_ACCEPTANCE, data, requestConfig)
}

async function fetchUserApps(requestConfig) {
  return http.get(apiUrls.IAM_OUTER_LIST_USER_APPLICATIONS, requestConfig)
}

async function fetchOktaUserInternal() {
  return http.get(apiUrls.IAM_OKTA_INTERNAL)
}

async function fetchOktaAppRequestAccessData(appId, requestConfig) {
  return http.get(apiUrls.IAM_OUTER_APP_GROUP_AND_URL.replace('{appId}', appId), requestConfig)
}

async function fetchIfUserBelongsToGroup(groupId, requestConfig) {
  return http.get(apiUrls.IAM_OUTER_USER_GROUP.replace('{groupId}', groupId), requestConfig)
}

async function assignUserToApplication(groupId, requestConfig) {
  return http.post(apiUrls.IAM_OUTER_ASSIGN_USER_TO_APPLICATION, {groupId: groupId}, requestConfig)
}

async function mfaEnabled(requestConfig) {
  const response = await http.post(apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_ENABLED, {}, requestConfig)
  if (!response || response.status !== 200) {
    return []
  }
  return response.data
}

async function mfaSubscribed(requestConfig) {
  const response = await http.post(apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_SUBSCRIBED, {}, requestConfig)
  if (!response || response.status !== 200) {
    return []
  }
  return response.data
}

async function mfaUnSubscribe(factorId, requestConfig) {
  const url = apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_UNSUBSCRIBE.replace('{FactorId}', factorId)
  return http.post(url, {}, requestConfig)
}

async function enrollUserToMfaFactor(factorType, profile, requestConfig) {
  return http.post(apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_ENROLL, {
    factorType,
    profile,
    activate: false
  }, requestConfig)
}

async function resendMfaChallenge(factorId, factorType, requestConfig) {
  return http.post(apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_RESEND.replace('{FactorId}', factorId), {
    factorType
  }, requestConfig)
}

async function activateMfaFactor(factorId, passCode, requestConfig) {
  return http.post(apiUrls.IAM_OUTER_OKTA_MFA_FACTOR_ACTIVATE.replace('{FactorId}', factorId), {
    passCode
  }, requestConfig)
}
