<template>
  <section class="help-container">
    <h3 class="okta-form-title o-form-head font-condensed" data-se="o-form-head">
      {{ $t('mfaSettings.pageTitle') }}
    </h3>
    <b-overlay
      :blur="$root.globalConfig.overlay.blur"
      :opacity="$root.globalConfig.overlay.opacity"
      :show="!user || loading"
      :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
      :variant="$root.globalConfig.overlay.variant"
      no-fade
      rounded
      z-index="100"
    >
      <div class="clearfix mb-4">
        <b-table-simple caption-top hover responsive small>
          <b-tbody>
            <b-tr v-if="mfaState.FACTOR_TYPE.OKTA.ENABLED">
              <b-th>Okta Verify</b-th>
              <b-th class="text-right">
                <b-button
                  v-if="mfaState.FACTOR_TYPE.OKTA.ACTIVE===false"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaSetup(mfaState.FACTOR_TYPE.OKTA.NAME)"
                >
                  {{ $t('mfaSettings.setup') }}
                </b-button>
                <b-button
                  v-if="mfaState.FACTOR_TYPE.OKTA.ACTIVE"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaRemove(mfaState.FACTOR_TYPE.OKTA.FactorId, mfaState.FACTOR_TYPE.OKTA.NAME)"
                >
                  {{ $t('mfaSettings.remove') }}
                </b-button>
              </b-th>
            </b-tr>
            <b-tr v-if="mfaState.FACTOR_TYPE.SMS.ENABLED" class="mt-4 border-bottom">
              <b-th class="pb-4">{{ $t('mfaSettings.smsAuthentication') }}</b-th>
              <b-th class="text-right">
                <b-button
                  v-if="mfaState.FACTOR_TYPE.SMS.ACTIVE===false"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaSetup(mfaState.FACTOR_TYPE.SMS.NAME)"
                >
                  {{ $t('mfaSettings.setup') }}
                </b-button>
                <b-button
                  v-if="mfaState.FACTOR_TYPE.SMS.ACTIVE"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaRemove(mfaState.FACTOR_TYPE.SMS.FactorId, mfaState.FACTOR_TYPE.SMS.NAME)"
                >
                  {{ $t('mfaSettings.remove') }}
                </b-button>
              </b-th>
            </b-tr>
            <b-tr v-if="mfaState.FACTOR_TYPE.EMAIL.ENABLED" class="border-bottom">
              <b-th class="pb-4 pt-4">{{ $t('mfaSettings.emailAuthentication') }}</b-th>
              <b-th class="text-right">
                <b-button
                  v-if="mfaState.FACTOR_TYPE.EMAIL.ACTIVE===false"
                  class="cursor-default button button-secondary text-primary"
                  type="button"
                  disabled
                  @click="mfaSetup(mfaState.FACTOR_TYPE.EMAIL.NAME)"
                >
                  {{ $t('mfaSettings.setup') }}
                </b-button>
                <b-button
                  v-if="mfaState.FACTOR_TYPE.EMAIL.ACTIVE"
                  class="cursor-default button button-secondary text-primary"
                  type="button"
                  disabled
                  @click="mfaRemove(mfaState.FACTOR_TYPE.EMAIL.FactorId, mfaState.FACTOR_TYPE.EMAIL.NAME)"
                >
                  {{ $t('mfaSettings.remove') }}
                </b-button>
              </b-th>
            </b-tr>
            <b-tr v-if="mfaState.FACTOR_TYPE.TOTP.ENABLED">
              <b-th class="pb-4 pt-4">{{ $t('mfaSettings.totpAuthentication') }}</b-th>
              <b-th class="text-right">
                <b-button
                  v-if="mfaState.FACTOR_TYPE.TOTP.ACTIVE===false"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaSetup(mfaState.FACTOR_TYPE.TOTP.NAME)"
                >
                  {{ $t('mfaSettings.setup') }}
                </b-button>
                <b-button
                  v-if="mfaState.FACTOR_TYPE.TOTP.ACTIVE"
                  class="button button-secondary text-primary"
                  type="button"
                  @click="mfaRemove(mfaState.FACTOR_TYPE.TOTP.FactorId, mfaState.FACTOR_TYPE.TOTP.NAME)"
                >
                  {{ $t('mfaSettings.remove') }}
                </b-button>
              </b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div class="o-form-button-bar">
        <b-button
          class="button button-secondary text-primary"
          type="button"
          @click="goBack()"
        >
          {{ $t('common.goBack') }}
        </b-button>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {getUrlParam, redirectUser} from '@/utils'
import {iamService} from '@/services/iamService'

export const STATE = {
  ACTIVE: 'ACTIVE',
  FACTOR_TYPE: {
    OKTA: {
      NAME: 'okta',
      ENABLED: false,
      ACTIVE: false,
      FactorId: ''
    },
    SMS: {
      NAME: 'sms',
      ENABLED: false,
      ACTIVE: false,
      FactorId: ''
    },
    EMAIL: {
      NAME: 'email',
      ENABLED: false,
      ACTIVE: false,
      FactorId: ''
    },
    TOTP: {
      NAME: 'token:software:totp',
      ENABLED: false,
      ACTIVE: false,
      FactorId: ''
    }
  }
}

export default {
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mfaState: STATE,
      loading: false,
      redirectUrl: getUrlParam('redirect_uri') || '/'
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      if (this.$route.meta.requiresAuth) {
        await this.setupTokens()
        await this.mfaSubscribed()
        await this.mfaEnabled()
      }
    },
    async setupTokens() {
      this.authenticated = await this.$auth.isAuthenticated()
      this.loading = false
      if (!this.authenticated) return
      this.tokens.accessToken = await this.$auth.getAccessToken()
      this.loading = false
    },
    goBack() {
      this.redirectUrl === '/'
        ? this.$router.push('/app/my-account')
        : redirectUser(this.redirectUrl)
    },
    async mfaRemove(factorId, factorType = '') {
      this.loading = true
      if (this.tokens.accessToken) {
        iamService.mfaUnSubscribe(factorId, this.requestConfig)
          .then(r => {
            const res_data = r.data._value
            if (res_data.status_code === 204) {
              const removedFactor = Object.entries(this.mfaState.FACTOR_TYPE).find((entry) => entry[1].NAME === factorType)
              removedFactor[1].ACTIVE = false
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    mfaEnabled: async function () {
      this.loading = true
      if (this.tokens.accessToken) {
        iamService.mfaEnabled(this.requestConfig)
          .then(r => {
            const res_data = r._value
            this.processEnabledData(this.mfaState.FACTOR_TYPE.EMAIL, res_data)
            this.processEnabledData(this.mfaState.FACTOR_TYPE.SMS, res_data)
            this.processEnabledData(this.mfaState.FACTOR_TYPE.OKTA, res_data)
            this.processEnabledData(this.mfaState.FACTOR_TYPE.TOTP, res_data)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    mfaSubscribed: async function () {
      this.loading = true
      if (this.tokens.accessToken) {
        iamService.mfaSubscribed(this.requestConfig)
          .then(r => {
            const res_data = r._value
            this.processSubscribedData(this.mfaState.FACTOR_TYPE.EMAIL, res_data)
            this.processSubscribedData(this.mfaState.FACTOR_TYPE.SMS, res_data)
            this.processSubscribedData(this.mfaState.FACTOR_TYPE.TOTP, res_data)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    processSubscribedData: function (factorType, res_data) {
      const currentFactor = res_data.filter(each_mfa_factor => each_mfa_factor.factorType === factorType.NAME)
      factorType.ACTIVE = currentFactor.some(each_mfa_factor => each_mfa_factor.status === this.mfaState.ACTIVE)
      factorType.FactorId = currentFactor.length > 0 ? currentFactor[0].id : ''
    },
    processEnabledData: function (factorType, res_data) {
      factorType.ENABLED = res_data.some(
        each_mfa_factor => each_mfa_factor.factorType === factorType.NAME
      )
    },
    mfaSetup: async function (factorType) {
      switch (factorType) {
      case 'email': {
        this.$router.push('/app/mfa-settings/email')
        break
      }
      case 'sms': {
        this.$router.push('/app/mfa-settings/sms')
        break
      }
      case 'token:software:totp': {
        this.$router.push('/app/mfa-settings/okta-verify')
        break
      }
      default: {
        break
      }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .cursor-default {
    cursor: default !important;
  }
</style>