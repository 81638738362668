<template>
  <b-overlay class="spinner-top"
             no-fade
             rounded
             :show="loading"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
  >
    <section class="help-container">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head text-capitalize">{{ transformedTitle }}</h2>
      <FormError v-if="error.present" :error="error.text" mode="infobox" />
      <div v-else v-html="value" />
    </section>
  </b-overlay>
</template>

<script>
import {contentService} from '@/services/contentService'
import FormError from '@/components/helpers/FormError.vue'

export default {
  components: {
    FormError
  },
  data() {
    return {
      loading: true,
      brand: this.$route.params.brand || '',
      region: this.$route.params.region || '',
      channel: this.$route.params.channel || '',
      filename: this.$route.params.title || '',
      title: this.$route.params.title || '',
      application: this.$route.params.application || '',
      error: {
        present: false,
        text: null
      },
      value: null
    }
  },
  computed: {
    transformedTitle() {
      return this.title.replace(/-/g, ' ')
    },
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    locale () {
      this.getToc()
    }
  },
  created() {
    this.getToc()
    this.error.text = `Unable to fetch toc for: ${this.brand}/${this.region}/${this.channel}`
  },
  methods: {
    async getToc() {
      try {
        this.error.present = false
        const res = await contentService.fetchTocDocument(this.brand, this.region, this.channel, this.application, this.filename, this.$i18n.locale)
        this.title = res.title
        this.value = res.data
      } catch (e) {
        this.error.present = true
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  #okta-sign-in {
    .help-container {
      position: relative;
      min-height: 10rem;

      ul {
        list-style: disc;
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }
    }
  }
</style>
