const domains = [
  'dibhids.net', 'dice-tools.com', 'bobcat.com', 'maxcontrolstaging.page.link',
  'maxcontrolexternalrelease.page.link', 'bobcatpartsonline.com', 'geith.com'
]

const regexes = domains.map(domain => {
  const pattern = domain.replace(/\\*\./g, '\\.')
  const regexp = new RegExp(`^(https?)://(?:\\w+[.-])*${pattern}(?:[/?][^ \\s]*)?$`)
  return regexp
})

export const isUrlSafe = (url) => regexes.some(regexp => regexp.test(url))