<template>
  <div class="interrupt-announcements mx-auto pb-4 bg-white" data-test-id="interrupt-announcements">
    <h4 data-se="o-form-head" class="okta-form-title o-form-head text-white m-0 text-left p-3 px-4 title-bar ">
      <div class="d-inline align-middle text-capitalize ml-2">{{ localLabels.announcements }}</div>
      <div class="bg-primary d-inline px-2 py-1 ml-3 rounded-circle small title-bar-badge ">{{ announcements.length }}</div>
    </h4>
    <div class="announcement-border">
      <h4 class="okta-form-title p-3 py-4 announcement-container mx-auto">{{ announcement.title }}</h4>
    </div>
    <div class="announcement-container help-container mx-auto">
      <div class="p-3 pt-4 pb-5" v-html="announcement.content" />
      <div v-if="!finalized" class="o-form-button-bar d-flex flex-column flex-md-row px-3 pb-8 justify-content-between">
        <button
          v-if="currentAnnouncement > 0"
          :class="buttonClasses"
          class="mb-4 mb-md-0"
          @click="displayPrevious"
        >
          <b-icon-chevron-left /> {{ localLabels.previous }}
        </button>
        <div v-else />

        <button
          v-if="currentAnnouncement < announcements.length - 1"
          :class="buttonClasses"
          @click="displayNext"
        >
          {{ localLabels.next }} <b-icon-chevron-right />
        </button>
        <button
          v-else
          :class="buttonClasses"
          @click="finalize"
        >
          <b-icon-x /> {{ localLabels.continue }}
        </button>
      </div>
      <div v-else class="text-center pb-8">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import {BIconChevronRight, BIconChevronLeft, BIconX, BSpinner} from 'bootstrap-vue'
import {announcementsService} from '@/services/announcementsService'
import {isUrlSafe} from '@/urlValidator'

export default {
  components: {
    BIconChevronRight,
    BIconChevronLeft,
    BIconX,
    BSpinner
  },
  props: {
    announcements: {
      type: Array,
      default: () => []
    },
    labels: {type: Object,
      default: () => {
        return {}
      }},
    language: {
      type: String,
      default: 'en'
    },
    completeFn: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      finalized: false,
      currentAnnouncement: 0,
      buttonClasses: 'button button-primary w-auto m-0 border-0 rounded-0'
    }
  },
  computed: {
    localLabels() {
      return {
        'announcements': this.labels?.['common.announcements'] || 'Announcements',
        'next': this.labels?.['common.next'] || 'Next',
        'previous': this.labels?.['common.previous'] || 'Previous',
        'continue': this.labels?.['common.continue'] || 'Continue'
      }
    },
    announcement() {
      return this.announcements[this.currentAnnouncement]?.contents?.[this.language]
        || this.announcements[this.currentAnnouncement]?.contents?.en
        || {title: '', content: ''}
    }
  },
  mounted: function() {
    const parentWrapper = document.getElementById('okta-sign-in')
    if(parentWrapper) {
      parentWrapper.style = 'max-width: 1118px; width: 100%; border: none;'
    }
    const authContent = document.getElementsByClassName('auth-content')
    if(authContent) {
      authContent[0].classList.remove('auth-content')
    }
  },
  created(){
    this.displayAnnouncements()
  },
  methods: {
    displayPrevious() {
      if(this.currentAnnouncement === 0) { return }
      this.currentAnnouncement--
    },
    displayNext() {
      if(this.currentAnnouncement >= this.announcements.length) { return }
      this.currentAnnouncement++
    },
    redirectUser(location) {
      window.location = location && isUrlSafe(location) ? location : '/'
    },
    finalize() {
      this.finalized = true
      this.redirectUser(this.$route.query.original_location)
    },
    async displayAnnouncements() {
      const {original_location} = this.$route.query
      const announcements = await announcementsService.getInterruptAnnouncements(original_location)

      if (!announcements.length){
        this.redirectUser(original_location)
      }
      this.announcements = announcements
    }
  }
}
</script>
<style lang="scss">
#okta-sign-in .interrupt-announcements {
  min-height: 10rem;

  .title-bar {
    background: #333 !important;
    font-size: 1.125rem;

    .title-bar-badge {
      font-size: 1rem !important;
    }
  }

  .announcement-container {
    color: #000;
    max-width: 768px;
    font-size: 1rem;

    ul, ol {
      padding-left: 1.25rem;
      margin-bottom: 1rem;

      li:not(:last-child) {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .announcement-border {
    border-bottom: 1px solid #D5DEE3 !important;
  }

  .spinner-border {
    border: 0.25em solid currentColor;
    border-right-color: transparent;
  }
}
</style>
