<template>
  <div class="container text-center">
    <div class="header">
      <img :alt="orgName" :src="orgLogo" class="org-logo">
    </div>
    <div class="content clearfix m-4">
      <h1>Processing Access Request...</h1>
      <div id="spinner" class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import doosanLogo from '@/assets/logo.png'
import {getUrlParam, redirectUser, transformApplicationName} from '@/utils'
import {iamService} from '@/services/iamService'

export const STATE = {
  INITIAL: 1,
  APPCHECK: 2,
  TOCCHECK: 3,
  TOCREQUEST: 4,
  REQUEST: 5,
  FINALIZED: 6,
  ERROR: 7
}

export default {
  name: 'RequestAccess',
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      state: STATE,
      loading: false,
      orgName: 'Bobcat Logo',
      orgLogo: doosanLogo,
      clientId: getUrlParam('client_id'),
      applicationData: null,
      transformedApplicationName: null,
      step: null
    }
  },
  computed: {
    tokensReady() {
      return this.tokens && this.tokens.accessToken
    },
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  watch: {
    tokensReady: {
      handler() {
        this.init()
      }
    },
    step: {
      handler() {
        if (this.step === this.state.APPCHECK) {
          this.checkIfUserIsAssignedToGroup()
        } else if (this.step === this.state.TOCCHECK) {
          this.getAcceptanceStatus()
        } else if (this.step === this.state.TOCREQUEST) {
          this.redirectForTocAcceptance()
        } else if (this.step === this.state.REQUEST) {
          this.assignUserToApplication()
        } else if (this.step === this.state.FINALIZED) {
          redirectUser(this.applicationData.redirectUrl)
        }
      }
    }
  },
  methods: {
    async init() {
      await this.initializeRequestAccess()
    },
    async assignUserToApplication() {
      try {
        await iamService.assignUserToApplication(this.applicationData.groupId, this.requestConfig)
        this.step = this.state.FINALIZED
      } catch (err) {
        const status = err?.response?.status || 500
        const message = err?.response?.data?.error?.message || 'Internal Server Error'
        this.$router.push(`/error?error_code=${status}&error_description=${err}&error=${message}`)
      }
    },
    async checkIfUserIsAssignedToGroup() {
      try {
        const response = await iamService.fetchIfUserBelongsToGroup(this.applicationData.groupId, this.requestConfig)
        const {assigned} = response.data
        if (assigned) {
          const status = '409'
          const message = 'Conflict'
          const description = this.$t('requestAccess.alreadyAssigned')
          this.$router.push(`/error?error_code=${status}&error_description=${description}&error=${message}`)
        } else {
          this.step = this.state.TOCCHECK
        }
      } catch (err) {
        const status = err?.response?.status || 500
        const message = err?.response?.data?.error?.message || 'Internal Server Error'
        this.$router.push(`/error?error_code=${status}&error_description=${err}&error=${message}`)
      }
    },
    async getAcceptanceStatus() {
      try {
        const response = await iamService.fetchTocAcceptance(this.requestConfig, this.transformedApplicationName)
        const {isLatestVersionAccepted} = response.data
        this.step = !isLatestVersionAccepted ? this.state.TOCREQUEST : this.state.REQUEST
      } catch (err) {
        const status = err?.response?.status || 500
        const message = err?.response?.data?.error?.message || 'Internal Server Error'
        this.$router.push(`/error?error_code=${status}&error_description=${err}&error=${message}`)
      }
    },
    async initializeRequestAccess() {
      if(!this.clientId) return
      try {
        const response = await iamService.fetchOktaAppRequestAccessData(this.clientId, this.requestConfig)
        if (!response.data) {
          const status = 403
          const error = 'Forbidden'
          const err = 'User is not assigned to this application'
          this.$router.push(`/error?error_code=${status}&error_description=${err}&error=${error}`)
        } else {
          this.applicationData = response.data
          this.transformedApplicationName = transformApplicationName(this.applicationData.appName)
          this.step = this.state.APPCHECK
        }
      } catch (err) {
        const status = err?.response?.status || 500
        const message = err?.response?.data?.error?.message || 'Internal Server Error'
        this.$router.push(`/error?error_code=${status}&error_description=${err}&error=${message}`)
      }
    },
    redirectForTocAcceptance() {
      redirectUser(`/app/toc?redirect_uri=${encodeURIComponent(window.location)}&application=${this.transformedApplicationName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
#back {
  width: 100% !important;
}
#spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.3em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  margin-top: 20px;
}
</style>
