<template>
  <section class="help-container">
    <h3 data-se="o-form-head" class="okta-form-title o-form-head font-condensed">
      {{ $t('mfaOktaVerify.pageTitle') }}
    </h3>
    <b-overlay
      :blur="$root.globalConfig.overlay.blur"
      :opacity="$root.globalConfig.overlay.opacity"
      :show="!user || loading"
      :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
      :variant="$root.globalConfig.overlay.variant"
      no-fade
      rounded
      z-index="100"
    >
      <div class="clearfix mb-4">
        <div v-if="state === State.DOWNLOAD_APP_PROMPT" class="content text-center" v-html="$t('mfaOktaVerify.downloadApp')" />
        <b-container v-if="state===State.QR_CODE" class="content">
          <b-row>
            <b-col md="5" cols="12">
              <p class="text-gray text-wrap">{{ $t('mfaOktaVerify.mobileApp') }}</p>
            </b-col>
            <b-col md="7" cols="12" class="text-center">
              <img :src="qrCodeUrl" alt="QR code" class="qr-code-border">
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right mr-2">
              <a @click="showSecretKey()">{{ $t('mfaOktaVerify.cantScan') }}</a>
            </b-col>
          </b-row>
        </b-container>
        <div v-if="state === State.CANT_SCAN_QR_CODE" class="content">
          <p class="text-center text-gray">{{ $t('mfaOktaVerify.secretKeyDescription') }}</p>
          <p class="text-center">{{ secretKey }}</p>
        </div>
        <div v-if="state === State.VERIFY_CODE" class="content">
          <p class="text-center text-gray">{{ $t('mfaOktaVerify.enterCodeDescription') }}</p>
          <label for="verificationCode" class="o-form-label">{{ $t('mfaOktaVerify.enterCode') }}</label>
          <b-form-input id="verificationCode" v-model="verificationCode" class="mr-1 font-base" />
        </div>
        <FormError :error="validation" mode="infobox" />
        <b-button class="button button-primary my-4" type="button" @click="nextStep">{{ $t('common.continue') }}</b-button>
        <b-button
          class="button button-secondary text-primary"
          type="button"
          @click="goBack()"
        >
          {{ $t('common.goBack') }}
        </b-button>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {iamService} from '@/services/iamService'
import FormError from '@/components/helpers/FormError.vue'

const FACTOR_TYPE = 'token:software:totp'
const STATUS_PENDING_ACTIVATION = 'PENDING_ACTIVATION'
const STATUS_ACTIVE = 'ACTIVE'

export const State = {
  DOWNLOAD_APP_PROMPT: 0,
  QR_CODE: 1,
  CANT_SCAN_QR_CODE: 2,
  VERIFY_CODE: 3
}

export default {
  components: {
    FormError
  },
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      state: State.DOWNLOAD_APP_PROMPT,
      verificationCode: '',
      factorId: '',
      qrCodeUrl: '',
      secretKey: '',
      validation: false,
      State
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  methods: {
    async init() {
      this.loading = true
      if (this.$route.meta.requiresAuth) {
        await this.setupTokens()
      }
    },
    async setupTokens() {
      this.authenticated = await this.$auth.isAuthenticated()
      this.loading = false
      if (!this.authenticated) return
      this.tokens.accessToken = await this.$auth.getAccessToken()
      this.loading = false
    },
    nextStep() {
      this.validation = ''
      switch (this.state) {
      case State.DOWNLOAD_APP_PROMPT:
        this.enrollToOktaVerify()
        break
      case State.QR_CODE:
      case State.CANT_SCAN_QR_CODE:
        this.qrCodeNextStep()
        break
      case State.VERIFY_CODE:
        this.verifyPassCode()
        break
      }
    },
    qrCodeNextStep() {
      this.state = State.VERIFY_CODE
    },
    showSecretKey() {
      this.state = State.CANT_SCAN_QR_CODE
    },
    translateEnrollErrorMessage(message) {
      if (message.includes('400')) {
        return this.$t('mfaOktaVerify.alreadySetUp')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else {
        return this.$t('mfaOktaVerify.error')
      }
    },
    validatePassCode(passCode) {
      return !!passCode && !!passCode.length
    },
    translateActivateErrorMessage(message) {
      if (message.includes('403')) {
        return this.$t('mfaOktaVerify.invalidPassCode')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else {
        return this.$t('mfaOktaVerify.error')
      }
    },
    async enrollToOktaVerify() {
      try {
        const {data} = await iamService.enrollUserToMfaFactor(FACTOR_TYPE, {}, this.requestConfig)
        if (data?._value?.status === STATUS_PENDING_ACTIVATION) {
          this.factorId = data._value.id
          this.qrCodeUrl = data._value._embedded.activation._links.qrcode.href
          this.secretKey = data._value._embedded.activation.sharedSecret
          this.state = State.QR_CODE
        }
        else if (data._value.status === STATUS_ACTIVE) {
          this.goBack()
        }
      } catch (e) {
        this.validation = this.translateEnrollErrorMessage(e.message)
      }
    },
    async verifyPassCode() {
      try {
        const code = this.verificationCode
        if (!this.validatePassCode(code)) {
          this.validation = this.$t('mfaOktaVerify.invalidPassCodeFormat')
          return
        }
        await iamService.activateMfaFactor(this.factorId, code, this.requestConfig)
        this.goBack()
      } catch(e) {
        this.validation = this.translateActivateErrorMessage(e.message)
      }
    },
    goBack() {
      this.$router.push('/app/mfa-settings')
    }
  }
}
</script>
<style lang="scss" scoped>

  @import '../scss/colors';

  .text-gray {
    color: $gray-700 !important;
  }

  .text-wrap {
    word-wrap: break-word;
  }

  .qr-code-border {
    border: 1px solid $gray-400 !important;
    border-radius: 5px;
  }

  button {
    width: 100%;
  }

  .o-form-has-errors {
    margin-top: 2rem !important;
  }
</style>
