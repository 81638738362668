import http, {isDevApplication} from '@/utils'

export const announcementsService = {
  getInterruptAnnouncements
}

async function getInterruptAnnouncements(redirectUri){
  try{
    const isDev = isDevApplication(redirectUri)
    const queryParams = new URLSearchParams({
      ...(isDev && {useDev: true})
    })
    const response = await http.get(`/announcements-data?${queryParams.toString()}`)
    if (!response || response.status !== 200) {
      return []
    }
    return response.data.items
  } catch {
    return []
  }
}
