<template>
  <header id="header" class="header">
    <b-navbar type="dark" class="p-0">
      <b-navbar-brand href="#"
                      class="header"
                      :class="[!authenticated ? 'full-width' : '', `header__brand--${brand}`]"
      >
        <b-img class="header__logo"
               :class="`header__logo--${brand}`"
               :alt="brand"
               :src="`/img/header/${brand}.png`"
        />
      </b-navbar-brand>

      <ProfileMenu v-if="user" :user="user" @on-logout="logout" />
    </b-navbar>
  </header>
</template>

<script>
import ProfileMenu from '@/components/helpers/ProfileMenu'
import config from '@/config'

export default {
  components: {
    ProfileMenu
  },
  props: {
    authenticated: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    }
  },
  computed: {
    username() {
      return this.user ? this.user.name : ''
    },
    brand() {
      return this.$route.query.brand ? (this.$route.query.brand === 'dpp' ? 'bobcat' : this.$route.query.brand) : config.brand
    }
  },
  methods: {
    logout() {
      this.$emit('on-logout')
    }
  }
}
</script>

<style lang="scss">
  @import "../scss/colors";
  @import '../scss/grid';

  .header {
    background-color: $black;
    position: relative;
    z-index: 102;

    &__logo {
      height: 35px;

      &--geith {
        height: 60px;
      }
    }

    &__brand {

      @include media-breakpoint-down(sm) {
        background-color: $black;
        text-align: center;
        width: auto;

        &.full-width {
          width: 100%;
        }
      }

      &--geith {
        padding-left: 5px;
        background-color: $black;
      }

      &--bobcat {
        padding: 1.125rem 2rem;
        background-color: $primary;
      }
    }

    .to-right {
      margin-left: auto;
    }

    .header-user-nav {
      padding: 0 20px;

      @include media-breakpoint-down(sm) {
        &__avatar {
          display: none;
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
      }

      .dropdown-item {
        text-transform: none;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
</style>
