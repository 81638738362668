<template>
  <section class="help-container" v-html="value" />
</template>

<script>
export default {
  props: {
    conceptName: {
      type: String,
      required: true,
      default: null
    },
    conceptPath: {
      type: String,
      required: false,
      default: 'help'
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    value() {
      return this.$t(`${this.conceptPath}.${this.conceptName}`)
    }
  }
}
</script>
