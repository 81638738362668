<template>
  <div v-if="error" class="o-form-has-errors">
    <p v-if="mode == 'inline'"
       id="input-container-error19"
       class="okta-form-input-error o-form-input-error o-form-explain"
       role="alert"
    >
      <span class="icon icon-16 error-16-small" role="img" aria-label="Error" />
      {{ error }}
    </p>

    <div v-if="mode == 'infobox'"
         class="okta-form-infobox-error infobox infobox-error"
         role="alert"
    >
      <span class="icon error-16" />
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    error: {
      default: false
    },
    mode: {
      type: String,
      default: 'inline'
    }
  }
}
</script>

<style lang="scss">
  #okta-sign-in.auth-container .o-form-explain.okta-form-input-error {
    word-break: break-word;
  }
</style>
