<template>
  <b-overlay class="spinner-top"
             no-fade
             rounded
             :show="loading"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
  >
    <FormError v-if="error.present" :error="error.text" mode="infobox" />
    <section class="help-container" v-html="value" />
  </b-overlay>
</template>

<script>
import {getLanguage} from '@/utils'
import {contentService} from '@/services/contentService'
import FormError from '@/components/helpers/FormError.vue'

export default {
  components: {
    FormError
  },
  data() {
    return {
      loading: true,
      region: this.$route.params.region || null,
      conceptPath: this.$route.params.conceptPath || null,
      conceptName: this.$route.params.conceptName || null,
      forcedLanguage: getLanguage(),
      value: '',
      error: {
        present: false,
        text: null
      }
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    locale () {
      this.forcedLanguage = this.locale
      if(!!this.region && this.region !== 'region') this.getData()
    }
  },
  created() {
    if(!this.region || this.region === 'region')
      this.determineRegion().then(() => this.getData())
    else
      this.getData()
  },
  methods: {
    determineRegion() {
      return contentService.fetchRegion().then(r => {
        this.region = r.data?.region?.toLowerCase() || null
        if(!this.region) {
          this.error.present = true
          this.error.text = this.$t('common.undefinedError')
          this.loading = false
        }
        return this.region
      }).catch(() => {
        this.error.present = true
        this.error.text = this.$t('common.undefinedError')
        this.loading = false
      })
    },
    getData() {
      this.loading = true
      this.error.present = false
      contentService.fetchTranslations( this.locale, this.region).then( r => {
        this.value = null
        if(r.data[this.conceptPath]) this.value = r.data[this.conceptPath][this.conceptName] || null
        if(this.value == null) {
          this.error.present = true
          this.error.text = this.$t('common.loadingError') + ` ${this.conceptPath} ${this.conceptName}`
        }
        this.loading = false
      }).catch(() => {
        this.error.present = true
        this.error.text = this.$t('common.loadingError') + ` ${this.conceptPath} ${this.conceptName}`
        this.loading = false
      })
    }
  }
}
</script>
