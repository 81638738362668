<template>
  <div :style="`background-image: url(/img/background/${brand}.png)`"
       class="main-area flex-grow-1 flex-shrink-0"
       :class="`main-area--${brand}`"
  >
    <div id="loginapp">
      <div id="okta-sign-in"
           class="auth-container main-container no-beacon mx-auto my-5"
           :class="{ 'box-wide': wide }"
      >
        <div class="okta-sign-in-header auth-header">
          <b-img :src="`/img/header/${brand}.png`" class="auth-org-logo" alt="" />
          <div class="beacon-container" />
        </div>
        <div class="auth-content">
          <div class="auth-content-inner">
            <div v-if="loadingIndicator" class="text-center mb-3">
              <b-spinner label="Spinning" />
            </div>
            <component :is="wraps" v-bind="$attrs" v-on="$listeners" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  inheritAttrs: false,
  props: {
    wraps: {
      required: true
    },
    wide: {
      type: Boolean,
      default: false
    },
    loadingIndicator: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    brand() {
      return this.$route.query.brand ? (this.$route.query.brand === 'portablepower' ? 'bobcat' : this.$route.query.brand) : config.brand
    }
  }
}
</script>
<style lang="scss" scoped>
  .spinner-border {
    border: 0.25em solid black !important;
    border-right-color: transparent !important;
  }
</style>
