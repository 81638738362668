import http from '@/utils'
import apiUrls from '@/api/apiUrls'

export const inviteService = {
  fetchTokenResponse,
  postUserData
}

async function fetchTokenResponse(token) {
  return http.get(apiUrls.IAM_OUTER_INVITE_VERIFY_TOKEN.replace('{token}', token))
}

async function postUserData(data) {
  return http.post(apiUrls.IAM_OUTER_INVITE_FINALIZE, data)
}
