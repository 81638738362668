<template>
  <a :href="app._links.appLinks[0].href + '?fromHome=true'" target="_blank">
    <b-card no-body class="p-2 mb-2 card-block d-flex">
      <b-card-body class="align-items-center d-flex flex-column justify-content-center">
        <b-img v-if="applicationLogo"
               v-b-tooltip.hover
               :src="applicationLogo"
               :title="applicationLabel"
               class="img-center img-fluid px-4"
        />
        <b-card-text class="pt-1 my-4 font-weight-bolder">
          {{ applicationLabel }}
        </b-card-text>
        <span v-if="applicationEnv" :class="'application-env env-' + applicationEnv.toLowerCase()">
          {{ applicationEnv }}
        </span>
      </b-card-body>
    </b-card>
  </a>
</template>

<script>

export default {
  props: {
    app: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      envRegex: /\s*\[(DEV|QA)\]/
    }
  },
  computed: {
    applicationLogo() {
      return false // TODO disabled until logos are designed
      // let logo = this.app._links.logo[0].href || false
      // logo = (!logo || logo.indexOf('/assets/img/logos/default') !== -1) ? false : logo
      // return logo
    },
    applicationEnv() {
      const match = this.app.label.match(this.envRegex)
      return match ? match[1] : false
    },
    applicationLabel() {
      return this.app.label.replace(this.envRegex, '')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/colors';

  .card {
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);

    cursor: pointer;
    height: 8rem;

    &:hover{
      box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }

    .card-body {
      padding: 0;
    }

    img {
      max-height: 5rem;
    }

    span.application-env {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      color: $white;
      padding: 0.25rem;
      border-radius: 0.5rem;

      &.env-dev {
        background: $orange;
      }

      &.env-qa {
        background: $black;
      }
    }
  }
</style>

