<template>
  <footer id="footer" class="bg-gray flex-shrink-0">
    <b-container class="bg-gray-800 pt-4">
      <b-row class="align-items-center">
        <b-col cols="12"
               md="4"
               class="mb-2 d-none d-md-block"
        >
          <b-img v-if="['bobcat', 'portablepower'].includes(brand)"
                 :src="`/img/footer/bobcat.png`"
                 class="mt-3 mb-4"
                 alt="One Tough Animal"
                 width="214"
          />
          <h4 v-if="brand === 'geith'" class="footer__copyright mt-3" style="font-family: 'Roboto'; text-transform: uppercase;">Premium Quality Excavator Attachments</h4>
          <h4 v-else-if="brand === 'ryan'" class="footer__copyright mt-3" style="font-family: 'Bicyclette'; text-transform: uppercase;">Trust Ryan</h4>
          <h4 v-else-if="brand === 'steiner'" class="footer__copyright mt-3" style="font-family: 'Helvetica Neue'; text-transform: uppercase;">It's not a tractor, it's a steiner</h4>
        </b-col>
        <b-col cols="12"
               md="8"
               class="mt-2 mb-2 text-center text-md-right"
        >
          <LanguageSwitcher /> &nbsp;
          <a href="#" class="footer-link">{{ $t('footer.privacyPolicy') }}</a>
          <a href="#" class="footer-link">{{ $t('footer.termsOfUse') }}</a>
          <a href="#" class="footer-link">{{ $t('footer.contactUs') }}</a>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="bg-white">
      <b-container>
        <b-row class="mt-3 py-4" data-test-id="footer-disclaimer">
          <b-col v-if="['bobcat', 'portablepower'].includes(brand)" cols="12" md="2" class="text-center pl-0 pb-3 pb-md-0">
            <b-img class="footer-logo mw-100"
                   alt="Doosan"
                   :src="require('@/assets/logo-doosan.png')"
            />
          </b-col>
          <b-col cols="12"
                 md="10"
                 class="small text-black pl-2"
                 data-test-id="footer-copy-text"
          >
            <span v-if="['bobcat', 'portablepower'].includes(brand)" v-html="this.$t('footer.text')" />
            <span v-if="['bobcat', 'portablepower'].includes(brand)" v-html="this.$t('footer.copyright')" />
            <span v-else-if="brand === 'geith'">© {{ new Date().getFullYear() }} Geith International Ltd.</span>
            <span v-else-if="['steiner', 'ryan'].includes(brand)">© {{ new Date().getFullYear() }} Clark Equipment Co. All rights reserved.</span>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </footer>
</template>
<script>
import LanguageSwitcher from '@/components/helpers/LanguageSwitcher'
import config from '@/config'

export default {
  components: {
    LanguageSwitcher
  },
  computed: {
    brand() {
      return this.$route.query.brand || config.brand
    }
  },
  watch: {
    '$route': 'applyBrandDesign'
  },
  mounted () {
    this.$nextTick(() => {
      this.applyBrandDesign()
    })
  },
  methods: {
    applyBrandDesign() {
      if(this.brand) {
        document.querySelector('body')?.setAttribute('class', (this.brand == 'portablepower' ? 'bobcat' : this.brand))
      }
    }
  }
}
</script>

<style lang="scss">
  @import "./../scss/login_brands";
</style>
