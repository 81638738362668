<template>
  <b-overlay :show="loading"
             no-fade
             rounded
             z-index="10000"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
             class="spinner-top"
  >
    <FormError :error="error" mode="infobox" />

    <div v-if="step === state.INITIAL" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">{{ $t('invite.tokenVerification') }}</h2>
    </div>

    <div v-else-if="step === state.INVITATION_FORM" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">{{ $t('invite.completeRegistration') }}</h2>
      <form class="custom-form">
        <b-form-group>
          <label for="invite-password">{{ $t('invite.password') }}</label>
          <b-form-input id="invite-password"
                        v-model="password"
                        :placeholder="this.$t('invite.password')"
                        type="password"
          />
          <FormError :error="validation.password" />

          <label for="invite-password-repeat">{{ $t('invite.passwordRepeat') }}</label>
          <b-form-input id="invite-password-repeat"
                        v-model="passwordRepeat"
                        :placeholder="this.$t('invite.passwordRepeat')"
                        type="password"
          />
          <FormError :error="validation.passwordRepeat" />

          <label for="invite-country">{{ $t('invite.country') }}</label>
          <b-form-select id="invite-country" v-model="countrySelected" :options="countries" />
          <FormError :error="validation.country" />

          <label for="invite-question">{{ $t('invite.recoveryQuestion') }}</label>
          <b-form-select id="invite-question" v-model="recoveryQuestionSelected" :options="recoveryQuestions" />

          <label for="invite-response">{{ $t('invite.recoveryAnswer') }}</label>
          <b-form-input id="invite-response"
                        v-model="recoveryAnswer"
                        :placeholder="this.$t('invite.recoveryAnswer')"
          />
          <FormError :error="validation.recoveryAnswer" />

          <b-form-checkbox
            id="invite-toc"
            v-model="toc"
            name="toc"
            :disabled="tocText === '...'"
          >
            <span v-html="tocText" />
          </b-form-checkbox>
          <FormError :error="validation.toc" />

          <div class="o-form-button-bar mt-4">
            <b-button id="okta-invite-submit"
                      class="button button-primary"
                      type="button"
                      value="Submit"
                      data-type="save"
                      @click="sendRequest"
            >
              {{ $t('invite.submit') }}
            </b-button>
          </div>
        </b-form-group>
      </form>
    </div>

    <div v-else-if="step === state.FINALIZED" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">
        <b-icon-check class="" font-scale="3" variant="success" /> <br>
        {{ $t('inviteDealer.success') }}
      </h2>

      <div class="mt-0">
        <b-button class="button button-primary w-100"
                  type="button"
                  :href="redirectUrl"
        >
          {{ $t('invite.logIn') }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {getUrlParam, changeTitleIntoUrl, transformArrayIntoDividedText} from '@/utils'
import {inviteService} from '@/services/inviteService'
import {contentService} from '@/services/contentService'
import FormError from '@/components/helpers/FormError.vue'
import {BIconCheck} from 'bootstrap-vue'
import '@/scss/custom.scss'

export const STATE = {
  INITIAL: 1,
  INVITATION_FORM: 2,
  FINALIZED: 3
}

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export default {
  components: {
    BIconCheck,
    FormError
  },
  props: {
    msg: String
  },
  data () {
    return {
      loading: true,
      state: STATE,
      step: STATE.INITIAL,
      error: false,
      tokenVerified: false,
      email: '',
      application: '',
      token: getUrlParam('token'),
      password: '',
      passwordRepeat: '',
      recoveryAnswer: '',
      tocUrls: [],
      countries: [{
        value: 'None',
        text: this.$t('invite.selectCountry'),
        region: null
      }],
      countrySelected: 'None',
      recoveryQuestionSelected: 'What is the food you least liked as a child?',
      defaultBrand: 'Bobcat',
      validation: {
        password: false,
        passwordRepeat: false,
        country: false,
        recoveryAnswer: false,
        toc: false
      },
      toc: false,
      redirectUrl: '/'
    }
  },
  computed: {
    recoveryQuestions() {
      return [
        {text: this.$t('invite.question-food'), value: 'What is the food you least liked as a child?'},
        {text: this.$t('invite.question-stuffedAnimal'), value: 'What is the name of your first stuffed animal?'},
        {text: this.$t('invite.question-award'), value: 'What did you earn your first medal or award for?'},
        {text: this.$t('invite.question-question'), value: 'What is your favorite security question?'},
        {text: this.$t('invite.question-stuffedToy'), value: 'What is the toy/stuffed animal you liked the most as a kid?'},
        {text: this.$t('invite.question-game'), value: 'What was the first computer game you played?'},
        {text: this.$t('invite.question-movie'), value: 'What is your favorite movie quote?'},
        {text: this.$t('invite.question-sportsTeam'), value: 'What was the mascot of the first sports team you played on?'},
        {text: this.$t('invite.question-purchase'), value: 'What music album or song did you first purchase?'},
        {text: this.$t('invite.question-art'), value: 'What is your favorite piece of art?'},
        {text: this.$t('invite.question-dessert'), value: 'What was your grandmother\'s favorite dessert?'},
        {text: this.$t('invite.question-cook'), value: 'What was the first thing you learned to cook?'},
        {text: this.$t('invite.question-job'), value: 'What was your dream job as a child?'},
        {text: this.$t('invite.question-spouse'), value: 'Where did you meet your spouse/significant other?'},
        {text: this.$t('invite.question-vacation'), value: 'Where did you go for your favorite vacation?'},
        {text: this.$t('invite.question-eve'), value: 'Where were you on New Year\'s Eve in the year 2000?'},
        {text: this.$t('invite.question-speaker'), value: 'Who is your favorite speaker/orator?'},
        {text: this.$t('invite.question-character'), value: 'Who is your favorite book/movie character?'},
        {text: this.$t('invite.question-player'), value: 'Who is your favorite sports player?'}
      ]
    },
    tocText() {
      if(this.tocUrls.length) return this.$t('invite.toc') + ' ' + transformArrayIntoDividedText(this.tocUrls)
      return '...'
    }
  },
  watch: {
    countrySelected (val) {
      this.toc = false
      this.getTocTextForBrandAndRegion(val.region, this.defaultBrand)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init() {
      this.verifyToken()
    },
    nextStep(_step = STATE.INITIAL) {
      this.error = ''
      this.step = _step
    },
    verifyToken() {
      this.loading = true
      inviteService.fetchTokenResponse(this.token).then(r => {
        this.tokenVerified = true
        this.application = r.data.applicationName || ''
        this.email = r.data.email || ''
        this.fetchCountries(r.data.disableLocationServices || false)
        this.nextStep(this.state.INVITATION_FORM)
      }).catch(r => {
        this.tokenVerified = false
        this.error = r.response?.data?.error?.message || this.$t('common.undefinedError')
      }).finally(() => {
        this.loading = false
        this.$scrollTo('#app')
      })
    },
    getTocTextForBrandAndRegion(region, brand) {
      region = region.toLowerCase()
      brand = brand.toLowerCase()
      this.tocUrls = []
      contentService.fetchTocPackage(brand, region, 'owner', this.application).then(r => {
        this.tocUrls = r.data.documents.map(d => {
          let title = d.title.en ? d.title.en : d.title
          return `<a href="/app/toc/${brand.toLowerCase()}/${region.toLowerCase()}/owner/${this.application !== '' ? this.application + '/' : ''}${changeTitleIntoUrl(title)}" target="_blank">${title}</a>`
        })
      }).catch(() => {
        this.error = `${this.$t('invite.tocFetchingError')} ${brand} ${region}`
      })
    },
    validatePassword() {
      function containsPartOfUsername(password, email) {
        const parts = email.toLowerCase().split(/[.,\-_#@]/g)
        return parts.some(part => password.toLowerCase().includes(part))
      }
      // At least 8 character(s)
      // At least 1 number(s)
      // At least 1 symbol(s)
      // At least 1 lowercase letter(s)
      // At least 1 uppercase letter(s)
      // Does not contain part of the username
      if (!PASSWORD_REGEX.test(this.password) || containsPartOfUsername(this.password, this.email)) {
        this.validation.password = this.$t('invite.invalidPasswordCriteria')
      } else {
        this.validation.password = ''
      }
    },
    validate() {
      this.validation.password = (this.password === '') ? `${this.$t('invite.password')} ${this.$t('invite.validation-empty')}` : false

      this.validation.passwordRepeat = (this.passwordRepeat === '') ? `${this.$t('invite.passwordRepeat')} ${this.$t('invite.validation-empty')}` : false
      this.validation.passwordRepeat = (this.password !== this.passwordRepeat) ? this.$t('invite.validation-passwordNotIdentical') : this.validation.passwordRepeat

      this.validation.recoveryAnswer = (this.recoveryAnswer === '') ? `${this.$t('invite.recoveryAnswer')} ${this.$t('invite.validation-empty')}` : false

      this.validation.country = (this.countrySelected === 'None') ? this.$t('invite.validation-noCountry') : false
      this.validation.toc = !this.toc ? this.$t('invite.validation-tocNotAccepted') : false

      this.validatePassword()

      return !Object.values(this.validation).some(x => x != false)
    },
    fetchCountries(disableLocationServices) {
      this.loading = true
      contentService.fetchCountries(disableLocationServices).then(r => {
        this.countries = this.countries.concat(
          r.data.available.map(country => {
            return {
              value: {
                country: country.countryCode,
                region: country.region
              },
              text: country.countryName
            }
          })
        )
        if (!disableLocationServices) {
          this.countrySelected = {
            country: r.data.detected.countryCode,
            region: r.data.detected.region
          }
        }
      }).catch(() => {
        this.error = this.$t('invite.countryFetchingError')
      }).finally(() => {
        this.loading = false
      })
    },
    sendRequest(e) {
      e.preventDefault()
      if(this.validate()) {
        this.loading = true
        inviteService.postUserData({
          'token': this.token,
          'password': this.password,
          'recoveryQuestion': this.recoveryQuestionSelected,
          'recoveryAnswer': this.recoveryAnswer,
          'countryCode': this.countrySelected.country,
          'application': this.application,
          'brand': this.defaultBrand
        }).then(r => {
          this.redirectUrl = r.data.redirectUrl || '/'
          this.nextStep(this.state.FINALIZED)
          this.$scrollTo('#app')
        }).catch(r => {
          if(r.response?.data?.error?.message) {
            try {
              const err = JSON.parse(r.response.data.error.message)
              err.errorCauses.forEach(e => {
                let [field, ...message] = e.errorSummary.split(':')

                // TODO: wait until backend makes the field names consistent and remove line below
                if(field == 'securityAnswer') field = 'recoveryAnswer'

                if(field in this.validation) this.validation[field] = message.join(':').trim()
                else this.error = e.errorSummary
              }, this )
            } catch {
              const field = r.response.data.error.message.split(':').pop().trim()
              if(field in this.validation) {
                this.validation[field] = this.$t(`invite.${field}FailedValidation`)
              }
              else {
                this.error = this.$t('common.undefinedError')
              }
            }
          }
          else this.error = this.$t('common.undefinedError')
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss">
  #loginapp #okta-sign-in {

    :focus {
      outline: 0 !important;
      box-shadow: none;
      border-color: inherit;
    }

    &.auth-container .custom-checkbox {
      padding-top: 1rem;

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: black;
        background-color: black;
      }

      label {
        background-image: none;
        padding: 0.25rem 0 0.125rem 1.5rem;

        &:before, &:after {
          top: 0.25rem;
          left: 0;
        }
      }
    }
  }
</style>
