<template>
  <div id="app">
    <Header :authenticated="authenticated"
            :user="user"
            @on-logout="logout"
    />
    <router-view :authenticated="authenticated"
                 :user="user"
                 :tokens="tokens"
                 @on-logout="logout"
    />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {applyLanguageToApplication} from '@/i18n'
import envConfig from '@/config'

export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      authenticated: false,
      user: null,
      tokens: {
        accessToken: null,
        idToken: null
      }
    }
  },
  watch: {
    '$route': 'isAuthenticated'
  },
  async beforeCreate() {
    await applyLanguageToApplication()
  },
  async created() {
    if (this.$route.meta.requiresAuth) await this.isAuthenticated()
  },
  methods: {
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
      if(!this.authenticated) return

      this.user = await this.getUser()
      this.tokens.accessToken = await this.$auth.getAccessToken()
      this.tokens.idToken = await this.$auth.getIdToken()
    },
    async getUser () {
      const user = await this.$auth.getUser()
      return user ? user : null
    },
    async logout () {
      if (envConfig.brand !== 'bobcat') {
        this.$cookies.set('sid', '')
      }
      await this.$auth.logout()
      await this.isAuthenticated()
    }
  }
}
</script>
