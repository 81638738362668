import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'fast-text-encoding'
import 'webcrypto-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import '@/scss/custom.scss'
import VueScrollTo from 'vue-scrollto'
import {i18n} from '@/i18n'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faGlobeAmericas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
library.add(faGlobeAmericas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.prototype.globalConfig = {
  overlay: {
    variant: 'white',
    spinnerVariant: 'primary',
    opacity: '.65',
    blur: '3px'
  }
}

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
