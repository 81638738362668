import config from '@/config'

const CONTENT_API_ROOT = config.contentApiUrl
const CONTENT_ACCOUNT_COMMON = CONTENT_API_ROOT + '/public/concepts/account/common/{path}'
const CONTENT_TRANSLATIONS = CONTENT_API_ROOT + '/public/translations/account/{path}/{lang}'
const CONTENT_LOCATIONS = CONTENT_API_ROOT + '/public/locations?disableLocationServices={disableLocationServices}'
const CONTENT_LOCATIONS_DETECTED = CONTENT_API_ROOT + '/public/locations/detected'
const CONTENT_COUNTRY_LOCATIONS = CONTENT_API_ROOT + '/public/locations/{countryCode}'
const CONTENT_TOC = CONTENT_API_ROOT + '/public/toc?region={region}&channel={channel}&brand={brand}'
const CONTENT_TOC_APP = CONTENT_API_ROOT + '/public/toc?region={region}&channel={channel}&application={application}&brand={brand}'

const PUBLIC_CONTENT_API_ROOT = config.publicContentApiUrl
const CONTENT_BRAND_INFO = PUBLIC_CONTENT_API_ROOT + '/public/brand/info/{application}'
const CONTENT_LANGUAGES = PUBLIC_CONTENT_API_ROOT + '/public/languages'

const PUBLIC_DEV_CONTENT_API_ROOT = config.publicDevContentApiUrl
const CONTENT_DEV_BRAND_INFO = PUBLIC_DEV_CONTENT_API_ROOT + '/public/brand/info/{application}'

const IAM_OUTER_INVITE_VERIFY_TOKEN = config.outerIamApiUrl + '/v2/invitation/token/{token}'
const IAM_OUTER_INVITE_FINALIZE = config.outerIamApiUrl + '/v2/invitation/finalize'

const IAM_OUTER_TOC_ACCEPTANCE = config.outerIamApiUrl + '/v2/toc/acceptance'
const IAM_OUTER_TOC_ACCEPTANCE_APP = config.outerIamApiUrl + '/v2/toc/acceptance?application={application}'

const IAM_OUTER_LIST_USER_APPLICATIONS = config.outerIamApiUrl + '/v2/me/apps'
const IAM_OUTER_ASSIGN_USER_TO_APPLICATION = config.outerIamApiUrl + '/v2/me/apps'

const IAM_OUTER_OKTA_MFA_FACTOR_ENABLED = config.outerIamApiUrl + '/v2/me/factors/enabled'
const IAM_OUTER_OKTA_MFA_FACTOR_SUBSCRIBED = config.outerIamApiUrl + '/v2/me/factors/subscribed'
const IAM_OUTER_OKTA_MFA_FACTOR_UNSUBSCRIBE = config.outerIamApiUrl + '/v2/me/factors/{FactorId}/unsubscribe'
const IAM_OUTER_OKTA_MFA_FACTOR_ENROLL = config.outerIamApiUrl + '/v2/me/factors'
const IAM_OUTER_OKTA_MFA_FACTOR_RESEND = config.outerIamApiUrl + '/v2/me/factors/{FactorId}/resend'
const IAM_OUTER_OKTA_MFA_FACTOR_ACTIVATE = config.outerIamApiUrl + '/v2/me/factors/{FactorId}/lifecycle/activate'

const IAM_OUTER_CHANGE_PASSWORD = config.outerIamApiUrl + '/v2/me/changepassword'
const IAM_OUTER_CHANGE_SECURITY_QUESTION = config.outerIamApiUrl + '/v2/me/changequestion'

const IAM_OUTER_USER_GROUP = config.outerIamApiUrl + '/v2/membership/groups/{groupId}/user'
const IAM_OUTER_APP_GROUP_AND_URL = config.outerIamApiUrl + '/v2/apps/groupUrl/{appId}'

const IAM_OKTA_INTERNAL = config.oktaHostedUrl + '/api/internal/enduser/home'
const PASSPORT_AURORA_API_ROOT = config.passportAuroraApiUrl
const PASSPORT_AURORA_API_IDENTITY = PASSPORT_AURORA_API_ROOT + '/identity'
const PASSPORT_AURORA_API_DESCENDANTS = PASSPORT_AURORA_API_ROOT + '/orgselector/descendants'
const PASSPORT_AURORA_API_SEARCH = PASSPORT_AURORA_API_ROOT + '/orgselector/search'

const DSA_URL = config.dsaUrl

export default {
  CONTENT_API_ROOT: CONTENT_API_ROOT,
  CONTENT_ACCOUNT_COMMON: CONTENT_ACCOUNT_COMMON,
  CONTENT_TRANSLATIONS: CONTENT_TRANSLATIONS,
  CONTENT_LOCATIONS: CONTENT_LOCATIONS,
  CONTENT_LOCATIONS_DETECTED: CONTENT_LOCATIONS_DETECTED,
  CONTENT_COUNTRY_LOCATIONS: CONTENT_COUNTRY_LOCATIONS,
  CONTENT_TOC: CONTENT_TOC,
  CONTENT_TOC_APP: CONTENT_TOC_APP,
  PUBLIC_CONTENT_API_ROOT: PUBLIC_CONTENT_API_ROOT,
  CONTENT_BRAND_INFO: CONTENT_BRAND_INFO,
  PUBLIC_DEV_CONTENT_API_ROOT: PUBLIC_DEV_CONTENT_API_ROOT,
  CONTENT_DEV_BRAND_INFO: CONTENT_DEV_BRAND_INFO,
  IAM_OUTER_INVITE_VERIFY_TOKEN: IAM_OUTER_INVITE_VERIFY_TOKEN,
  IAM_OUTER_INVITE_FINALIZE: IAM_OUTER_INVITE_FINALIZE,
  CONTENT_LANGUAGES: CONTENT_LANGUAGES,
  IAM_OUTER_TOC_ACCEPTANCE: IAM_OUTER_TOC_ACCEPTANCE,
  IAM_OUTER_TOC_ACCEPTANCE_APP: IAM_OUTER_TOC_ACCEPTANCE_APP,
  IAM_OUTER_LIST_USER_APPLICATIONS: IAM_OUTER_LIST_USER_APPLICATIONS,
  IAM_OUTER_ASSIGN_USER_TO_APPLICATION: IAM_OUTER_ASSIGN_USER_TO_APPLICATION,
  IAM_OUTER_OKTA_MFA_FACTOR_ENABLED: IAM_OUTER_OKTA_MFA_FACTOR_ENABLED,
  IAM_OUTER_OKTA_MFA_FACTOR_SUBSCRIBED: IAM_OUTER_OKTA_MFA_FACTOR_SUBSCRIBED,
  IAM_OUTER_OKTA_MFA_FACTOR_UNSUBSCRIBE: IAM_OUTER_OKTA_MFA_FACTOR_UNSUBSCRIBE,
  IAM_OUTER_OKTA_MFA_FACTOR_ENROLL: IAM_OUTER_OKTA_MFA_FACTOR_ENROLL,
  IAM_OUTER_OKTA_MFA_FACTOR_RESEND: IAM_OUTER_OKTA_MFA_FACTOR_RESEND,
  IAM_OUTER_OKTA_MFA_FACTOR_ACTIVATE: IAM_OUTER_OKTA_MFA_FACTOR_ACTIVATE,
  IAM_OKTA_INTERNAL: IAM_OKTA_INTERNAL,
  IAM_OUTER_USER_GROUP,
  IAM_OUTER_APP_GROUP_AND_URL,
  PASSPORT_AURORA_API_IDENTITY,
  PASSPORT_AURORA_API_DESCENDANTS,
  PASSPORT_AURORA_API_SEARCH,
  DSA_URL,
  IAM_OUTER_CHANGE_PASSWORD,
  IAM_OUTER_CHANGE_SECURITY_QUESTION
}
