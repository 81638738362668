<template>
  <b-overlay :show="loading"
             no-fade
             rounded
             z-index="10000"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
             class="spinner-top"
  >
    <FormError :error="error" mode="infobox" />

    <div v-if="step === state.INITIAL" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">{{ $t('inviteDealer.tokenVerification') }}</h2>
    </div>

    <div v-else-if="step === state.INVITATION_FORM" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">{{ $t('inviteDealer.completeProfile') }}</h2>
      <form class="custom-form">
        <b-form-group>
          <label for="inviteDealer-emailAddress">{{ $t('inviteDealer.emailAddress') }}</label>
          <p class="text-left"><strong v-text="email" /></p>

          <label for="inviteDealer-password">{{ $t('inviteDealer.password') }}</label>
          <b-form-input id="inviteDealer-password"
                        v-model="password"
                        :placeholder="this.$t('inviteDealer.password')"
                        type="password"
          />
          <FormError :error="validation.password" />

          <label for="inviteDealer-password-repeat">{{ $t('inviteDealer.passwordRepeat') }}</label>
          <b-form-input id="inviteDealer-password-repeat"
                        v-model="passwordRepeat"
                        :placeholder="this.$t('inviteDealer.passwordRepeat')"
                        type="password"
          />
          <FormError :error="validation.passwordRepeat" />

          <label for="inviteDealer-phoneNumber">{{ $t('inviteDealer.phoneNumber') }}</label>
          <b-form-select id="inviteDealer-phoneNumber-countryCode"
                         v-model="phoneNumberCountryCodeSelected"
                         :options="countryCodesOptions"
          />
          <b-form-input id="inviteDealer-phoneNumber"
                        v-model="phoneNumber"
                        :placeholder="this.$t('inviteDealer.phoneNumber')"
                        @update="onPhoneNumberInput"
                        @keydown.enter="onPhoneNumberInput"
          />
          <b-row>
            <FormError :error="validation.phoneNumber" />
          </b-row>

          <label for="inviteDealer-mobilePhoneNumber">{{ $t('inviteDealer.mobilePhoneNumber') }}</label>
          <b-form-select id="inviteDealer-mobilePhoneNumber-countryCode"
                         v-model="mobilePhoneNumberCountryCodeSelected"
                         :options="countryCodesOptions"
          />
          <b-form-input id="inviteDealer-mobilePhoneNumber"
                        v-model="mobilePhoneNumber"
                        :placeholder="this.$t('inviteDealer.mobilePhoneNumber')"
                        @update="onMobilePhoneNumberInput"
                        @keydown.enter="onMobilePhoneNumberInput"
          />
          <b-row>
            <FormError :error="validation.mobilePhoneNumber" />
          </b-row>

          <label for="inviteDealer-faxNumber">{{ $t('inviteDealer.faxNumber') }}</label>
          <b-form-select id="inviteDealer-faxNumber-countryCode"
                         v-model="faxNumberCountryCodeSelected"
                         :options="countryCodesOptions"
          />
          <b-form-input id="inviteDealer-faxNumber"
                        v-model="faxNumber"
                        :placeholder="this.$t('inviteDealer.faxNumber')"
                        @update="onFaxNumberInput"
                        @keydown.enter="onFaxNumberInput"
          />
          <b-row>
            <FormError :error="validation.faxNumber" />
          </b-row>

          <label for="inviteDealer-question">{{ $t('inviteDealer.recoveryQuestion') }}</label>
          <b-form-select id="inviteDealer-question" v-model="recoveryQuestionSelected" :disable="this.$t('inviteDealer.question-food')" :options="recoveryQuestions" />

          <label for="inviteDealer-response">{{ $t('inviteDealer.securityAnswer') }}</label>
          <b-form-input id="inviteDealer-response"
                        v-model="securityAnswer"
                        :placeholder="this.$t('inviteDealer.securityAnswerRequired')"
          />
          <FormError :error="validation.securityAnswer" />

          <b-form-checkbox id="invite-toc" v-model="toc" name="toc" :disabled="tocText === '...'">
            <span v-html="tocText" />
          </b-form-checkbox>
          <FormError :error="validation.toc" />

          <br>
          <p class="text-left">{{ $t('inviteDealer.required') }} </p>

          <div class="o-form-button-bar mt-4">
            <b-button id="okta-invite-submit"
                      class="button button-primary"
                      type="button"
                      value="Submit"
                      data-type="save"
                      @click="sendRequest"
            >
              {{ $t('inviteDealer.completeProfile') }}
            </b-button>
          </div>
        </b-form-group>
      </form>
    </div>

    <div v-else-if="step === state.FINALIZED" class="clearfix">
      <h2 data-se="o-form-head" class="okta-form-title o-form-head">
        <b-icon-check class="" font-scale="3" variant="success" /> <br>
        {{ $t('inviteDealer.success') }}
      </h2>

      <div class="mt-0">
        <b-button class="button button-primary w-100"
                  type="button"
                  :href="redirectUrl"
        >
          {{ $t('inviteDealer.goToDealerHome') }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {changeTitleIntoUrl, getUrlParam, transformArrayIntoDividedText} from '@/utils'
import {inviteService} from '@/services/inviteService'
import FormError from '@/components/helpers/FormError.vue'
import {BIconCheck} from 'bootstrap-vue'
import '@/scss/custom.scss'
import {contentService} from '@/services/contentService'
import countryCodes from '../assets/country_codes.json'
import {parsePhoneNumber, isValidPhoneNumber} from 'libphonenumber-js'


export const STATE = {
  INITIAL: 1,
  INVITATION_FORM: 2,
  FINALIZED: 3
}
export const PARSED = 'Parsed'
export const COUNTRY_CODE_SELECTED = 'CountryCodeSelected'
export const PHONE_NUMBER_REGEX = /^\+[1-9]\d{1,14}$/
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export default {
  components: {
    BIconCheck,
    FormError
  },
  props: {
    msg: String
  },
  data () {
    return {
      loading: true,
      state: STATE,
      step: STATE.INITIAL,
      error: false,
      tokenVerified: false,
      email: '',
      phoneNumber: '',
      phoneNumberParsed: '',
      phoneNumberCountryCodeSelected: {code: 'US', dialCode: '+1'},
      mobilePhoneNumber: '',
      mobilePhoneNumberParsed: '',
      mobilePhoneNumberCountryCodeSelected: {code: 'US', dialCode: '+1'},
      faxNumber: '',
      faxNumberParsed: '',
      faxNumberCountryCodeSelected: {code: 'US', dialCode: '+1'},
      token: getUrlParam('token'),
      password: '',
      passwordRepeat: '',
      securityAnswer: '',
      logOutLink: '',
      brandSelected: '',
      recoveryQuestionSelectedInit: '',
      region: '',
      application: '',
      channel: 'dealer',
      toc: false,
      tocUrls: [],
      countryCodesOptions: '',
      validation: {
        password: false,
        passwordRepeat: false,
        securityAnswer: false,
        phoneNumber: false,
        mobilePhoneNumber: false,
        faxNumber: false,
        toc: false
      },
      redirectUrl: '/'
    }
  },
  computed: {
    recoveryQuestions() {
      return [
        {text: this.$t('inviteDealer.question-food'), value: this.$t('inviteDealer.question-food')},
        {text: this.$t('inviteDealer.question-stuffedAnimal'), value: this.$t('inviteDealer.question-stuffedAnimal')},
        {text: this.$t('inviteDealer.question-award'), value: this.$t('inviteDealer.question-award')},
        {text: this.$t('inviteDealer.question-question'), value: this.$t('inviteDealer.question-question')},
        {text: this.$t('inviteDealer.question-stuffedToy'), value: this.$t('inviteDealer.question-stuffedToy')},
        {text: this.$t('inviteDealer.question-game'), value: this.$t('inviteDealer.question-game')},
        {text: this.$t('inviteDealer.question-movie'), value: this.$t('inviteDealer.question-movie')},
        {text: this.$t('inviteDealer.question-sportsTeam'), value: this.$t('inviteDealer.question-sportsTeam')},
        {text: this.$t('inviteDealer.question-purchase'), value: this.$t('inviteDealer.question-purchase')},
        {text: this.$t('inviteDealer.question-art'), value: this.$t('inviteDealer.question-art')},
        {text: this.$t('inviteDealer.question-dessert'), value: this.$t('inviteDealer.question-dessert')},
        {text: this.$t('inviteDealer.question-cook'), value: this.$t('inviteDealer.question-cook')},
        {text: this.$t('inviteDealer.question-job'), value: this.$t('inviteDealer.question-job')},
        {text: this.$t('inviteDealer.question-spouse'), value: this.$t('inviteDealer.question-spouse')},
        {text: this.$t('inviteDealer.question-vacation'), value: this.$t('inviteDealer.question-vacation')},
        {text: this.$t('inviteDealer.question-eve'), value: this.$t('inviteDealer.question-eve')},
        {text: this.$t('inviteDealer.question-speaker'), value: this.$t('inviteDealer.question-speaker')},
        {text: this.$t('inviteDealer.question-character'), value: this.$t('inviteDealer.question-character')},
        {text: this.$t('inviteDealer.question-player'), value: this.$t('inviteDealer.question-player')}
      ]
    },
    recoveryQuestionSelected: {
      get: function () {
        if (this.recoveryQuestionSelectedInit !== '') {
          return this.recoveryQuestionSelectedInit
        }
        return this.$t('inviteDealer.question-food')
      },
      set: function (newValue) {
        this.recoveryQuestionSelectedInit = newValue
      }
    },
    tocText() {
      return this.tocUrls.length ? this.$t('invite.toc') + ' ' + transformArrayIntoDividedText(this.tocUrls) : '...'
    }
  },
  watch: {
    recoveryQuestions() {
      this.recoveryQuestionSelected = this.$t('inviteDealer.question-food')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init() {
      this.verifyToken()
      this.setupCountryCodesOptions()
    },
    nextStep(_step = STATE.INITIAL) {
      this.error = ''
      this.step = _step
    },
    verifyToken() {
      this.loading = true
      inviteService.fetchTokenResponse(this.token).then(r => {
        this.brandSelected = r.data.brand || 'Bobcat'
        this.region = r.data.region || 'NA'
        this.application = r.data.applicationName || ''
        this.email = r.data.email || ''
        this.tokenVerified = true
        this.getTocData(this.region, this.brandSelected, this.channel, this.application)
        this.loading = true
        this.nextStep(this.state.INVITATION_FORM)
      }).catch(r => {
        this.tokenVerified = false
        this.error = r.response?.data?.error?.message || this.$t('common.undefinedError')
      }).finally(() => {
        this.loading = false
        this.$scrollTo('#app')
      })
    },
    validateNumber(numberType, errorMessage) {
      if(!this[numberType]) { //empty number so nothing to validate so its OK!
        this.validation[numberType] = ''
      } else if (!this[numberType + PARSED]) { //number too short or too long to parse so it is NOT OK !
        this.validation[numberType] = this.$t(`inviteDealer.${errorMessage}`)
      } else if(this.isValidNumberForLibrary(numberType) && this.isValidNumberForRegex(numberType)) {
        this.validation[numberType] = ''
      } else {
        this.validation[numberType] = this.$t(`inviteDealer.${errorMessage}`)
      }
    },
    isValidNumberForLibrary(numberType) {
      return isValidPhoneNumber(this[numberType], this[numberType + COUNTRY_CODE_SELECTED].code)
    },
    isValidNumberForRegex(numberType) {
      const numberWithRemovedSpaces = this[numberType + PARSED].replace(/\s/g, '')
      return numberWithRemovedSpaces && PHONE_NUMBER_REGEX.test(numberWithRemovedSpaces)
    },
    validatePassword() {
      function containsPartOfUsername(password, email) {
        const parts = email.toLowerCase().split(/[.,\-_#@]/g)
        return parts.some(part => password.toLowerCase().includes(part))
      }
      // At least 8 character(s)
      // At least 1 number(s)
      // At least 1 symbol(s)
      // At least 1 lowercase letter(s)
      // At least 1 uppercase letter(s)
      // Does not contain part of the username
      if (!PASSWORD_REGEX.test(this.password) || containsPartOfUsername(this.password, this.email)) {
        this.validation.password = this.$t('inviteDealer.invalidPasswordCriteria')
      } else {
        this.validation.password = ''
      }
    },
    validateSecurityAnswerLength() {
      if (this.securityAnswer.length < 4) {
        return this.validation.securityAnswer = this.$t('inviteDealer.securityAnswerLengthError')
      } else {
        return false
      }
    },
    validateSecurityAnswer() {
      const selectedQuestion = this.recoveryQuestionSelected.toLowerCase()
      const answer = this.securityAnswer.toLowerCase().trim()
      if (selectedQuestion.includes(answer)) {
        return this.validation.securityAnswer = this.$t('inviteDealer.answerInQuestion')
      } else {
        this.validation.securityAnswer = ''
      }
    },
    validate() {
      this.validation.password = (this.password === '') ? `${this.$t('inviteDealer.password')} ${this.$t('inviteDealer.validation-empty')}` : false
      this.validation.passwordRepeat = (this.passwordRepeat === '') ? `${this.$t('inviteDealer.passwordRepeat')} ${this.$t('inviteDealer.validation-empty')}` : false
      this.validation.passwordRepeat = (this.password !== this.passwordRepeat) ? this.$t('inviteDealer.validation-passwordNotIdentical') : this.validation.passwordRepeat
      this.validation.securityAnswer = (this.securityAnswer === '') ? `${this.$t('inviteDealer.securityAnswer')} ${this.$t('inviteDealer.validation-empty')}` : this.validateSecurityAnswer() || this.validateSecurityAnswerLength()
      this.validation.toc = !this.toc ? this.$t('invite.validation-tocNotAccepted') : false
      this.validateNumber('phoneNumber', 'invalidPhoneNumber')
      this.validateNumber('mobilePhoneNumber', 'invalidMobilePhoneNumber')
      this.validateNumber('faxNumber', 'invalidFaxNumber')
      this.validatePassword()
      return !Object.values(this.validation).some(x => x != false)
    },
    sendRequest(e) {
      e.preventDefault()
      if(this.validate()) {
        this.loading = true
        inviteService.postUserData({
          'token': this.token,
          'password': this.password,
          'phoneNumber': this.phoneNumberParsed,
          'mobilePhoneNumber': this.mobilePhoneNumberParsed,
          'faxNumber': this.faxNumberParsed,
          'recoveryQuestion': this.recoveryQuestionSelected,
          'recoveryAnswer': this.securityAnswer
        }).then(r => {
          this.redirectUrl = r.data.redirectUrl || '/'
          this.nextStep(this.state.FINALIZED)
          this.$scrollTo('#app')
        }).catch(r => {
          if(r.response?.data?.error?.message) {
            try {
              const err = JSON.parse(r.response.data.error.message)
              err.errorCauses.forEach(e => {
                let [field, ...message] = e.errorSummary.split(':')

                if(field in this.validation) this.validation[field] = message.join(':').trim()
                else this.error = e.errorSummary
              }, this )
            }
            catch {
              const field = r.response.data.error.message.split(':').pop().trim()
              if(field in this.validation) {
                this.validation[field] = this.$t(`inviteDealer.${field}FailedValidation`)
              }
              else {
                this.error = this.$t('common.undefinedError')
              }
            }
          }
          else this.error = this.$t('common.undefinedError')
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getTocData(region, brand, channel, application) {
      region = region.toLowerCase()
      brand = brand.toLowerCase()
      this.tocUrls = []
      contentService.fetchTocPackage(brand, region, channel, application).then(r => {
        this.tocUrls = r.data.documents.map(d => {
          let title = d.title.en ? d.title.en : d.title
          return `<a href="/app/toc/${brand}/${region}/${channel}/${application !== '' ? application + '/' : ''}${changeTitleIntoUrl(title)}" target="_blank">${title}</a>`
        })
      }).catch(() => {
        this.error = `${this.$t('invite.tocFetchingError')} ${brand} ${region} ${channel} ${application}`
      })
    },
    setupCountryCodesOptions() {
      this.countryCodesOptions = countryCodes
        .map((c) => { return {
          text: `${c.name} (${c.dial_code})`,
          value: {
            code: `${c.code}`,
            dialCode: `${c.dial_code}`
          }
        }})
    },
    onPhoneNumberInput() {
      this.parseNumberAndValidate(this.phoneNumber, this.phoneNumberCountryCodeSelected, 'phoneNumber', 'invalidPhoneNumber')
    },
    onMobilePhoneNumberInput() {
      this.parseNumberAndValidate(this.mobilePhoneNumber, this.mobilePhoneNumberCountryCodeSelected, 'mobilePhoneNumber', 'invalidMobilePhoneNumber')
    },
    onFaxNumberInput() {
      this.parseNumberAndValidate(this.faxNumber, this.faxNumberCountryCodeSelected, 'faxNumber', 'invalidFaxNumber')
    },
    parseNumberAndValidate(number, selectedCountryOption, numberType, errorMessage) {
      try {
        const phoneNumber = parsePhoneNumber(number, selectedCountryOption.code)
        if (phoneNumber.country) {
          this[numberType + COUNTRY_CODE_SELECTED] = this.countryCodesOptions.find((o) => o.value.code === phoneNumber.country).value
        }
        if (phoneNumber.number) {
          this[numberType + PARSED] = phoneNumber.number
        }
      } catch (e) {
        this[numberType + PARSED] = ''
      }
      this.validateNumber(numberType, errorMessage)
    }
  }
}
</script>

<style lang="scss">
  #loginapp #okta-sign-in {

    :focus {
      outline: 0 !important;
      box-shadow: none;
      border-color: inherit;
    }

    &.auth-container .custom-checkbox {
      padding-top: 1rem;

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: black;
        background-color: black;
      }

      label {
        background-image: none;
        padding: 0.25rem 0 0.125rem 1.5rem;

        &:before, &:after {
          top: 0.25rem;
          left: 0;
        }
      }
    }
  }
</style>
