<template>
  <form class="custom-form">
    <div class="custom-form__changePassword">
      <h3
        v-if="!changePassword && !finalized.changePassword"
        class="text-center text-primary text-bold custom-form__header"
        role="button"
        @click="changePassword = !changePassword"
      >
        {{ $t('changePassword.changePasswordButtonText') }}
      </h3>
      <div v-if="changePassword && !finalized.changePassword">
        <b-form-group>
          <label for="password-current">{{
            $t('changePassword.currentPassword')
          }}</label>
          <b-form-input
            id="password-current"
            v-model="passwordCurrent"
            :placeholder="this.$t('changePassword.currentPassword')"
            type="password"
          />
          <FormError :error="validation.current" />

          <label for="password-new">{{
            $t('changePassword.newPassword')
          }}</label>
          <b-form-input
            id="password-new"
            v-model="passwordNew"
            :placeholder="this.$t('changePassword.newPassword')"
            type="password"
          />
          <FormError :error="validation.new" />

          <label for="password-confirm">{{
            $t('changePassword.confirmNewPassword')
          }}</label>
          <b-form-input
            id="password-confirm"
            v-model="passwordConfirm"
            :placeholder="this.$t('changePassword.confirmNewPassword')"
            type="password"
          />
          <FormError :error="validation.confirm" />
        </b-form-group>
      </div>
      <div v-if="finalized.changePassword" class="clearfix">
        <h3 data-se="o-form-head" class="okta-form-title o-form-head">
          <b-icon-check class="" font-scale="3" variant="success" />
          <br>
          {{ $t('changePassword.success') }}
        </h3>
      </div>
    </div>
    <hr>
    <div class="custom-form__changeSecurityQuestion">
      <h3
        v-if="!changeSecurityQuestion && !finalized.changeSecurityQuestion"
        class="text-center text-primary custom-form__header"
        role="button"
        @click="changeSecurityQuestion = !changeSecurityQuestion"
      >
        {{ $t('changeSecurityQuestion.changeSecurityQuestionButtonText') }}
      </h3>
      <div v-if="changeSecurityQuestion && !finalized.changeSecurityQuestion">
        <b-form-group>
          <label for="invite-question">{{
            $t('invite.recoveryQuestion')
          }}</label>
          <b-form-select
            id="recovery-question"
            v-model="recoveryQuestionSelected"
            :options="recoveryQuestions"
          />

          <label for="invite-response">{{ $t('invite.recoveryAnswer') }}</label>
          <b-form-input
            id="invite-response"
            v-model="recoveryAnswer"
            :placeholder="this.$t('invite.recoveryAnswer')"
          />

          <FormError :error="validation.recoveryAnswer" />
        </b-form-group>
      </div>
      <div v-if="finalized.changeSecurityQuestion" class="clearfix">
        <h3 data-se="o-form-head" class="okta-form-title o-form-head">
          <b-icon-check class="" font-scale="3" variant="success" />
          <br>
          {{ $t('changeSecurityQuestion.changeSecurityQuestionSuccess') }}
        </h3>
      </div>
    </div>
    <div class="o-form-button-bar mt-4">
      <b-button
        id="password-submit"
        class="button button-primary"
        type="button"
        value="Submit"
        data-type="save"
        :disabled="!changePassword && !changeSecurityQuestion"
        @click="sendRequest"
      >
        {{ $t('common.save') }}
      </b-button>
    </div>
  </form>
</template>

<script>
import http, {hasWhiteSpace} from '@/utils'
import FormError from '@/components/helpers/FormError.vue'
import {BIconCheck} from 'bootstrap-vue'
import apiUrls from '@/api/apiUrls'

export default {
  components: {
    BIconCheck,
    FormError
  },
  props: {
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      finalized: {
        changePassword: false,
        changeSecurityQuestion: false
      },
      changePassword: false,
      changeSecurityQuestion: false,
      passwordCurrent: '',
      passwordNew: '',
      passwordConfirm: '',
      recoveryQuestionSelected: 'What is the food you least liked as a child?',
      recoveryAnswer: '',
      validation: {
        current: false,
        new: false,
        confirm: false,
        recoveryAnswer: false
      }
    }
  },
  computed: {
    recoveryQuestions() {
      return [
        {
          text: this.$t('invite.question-food'),
          value: 'What is the food you least liked as a child?'
        },
        {
          text: this.$t('invite.question-stuffedAnimal'),
          value: 'What is the name of your first stuffed animal?'
        },
        {
          text: this.$t('invite.question-award'),
          value: 'What did you earn your first medal or award for?'
        },
        {
          text: this.$t('invite.question-question'),
          value: 'What is your favorite security question?'
        },
        {
          text: this.$t('invite.question-stuffedToy'),
          value: 'What is the toy/stuffed animal you liked the most as a kid?'
        },
        {
          text: this.$t('invite.question-game'),
          value: 'What was the first computer game you played?'
        },
        {
          text: this.$t('invite.question-movie'),
          value: 'What is your favorite movie quote?'
        },
        {
          text: this.$t('invite.question-sportsTeam'),
          value: 'What was the mascot of the first sports team you played on?'
        },
        {
          text: this.$t('invite.question-purchase'),
          value: 'What music album or song did you first purchase?'
        },
        {
          text: this.$t('invite.question-art'),
          value: 'What is your favorite piece of art?'
        },
        {
          text: this.$t('invite.question-dessert'),
          value: 'What was your grandmother\'s favorite dessert?'
        },
        {
          text: this.$t('invite.question-cook'),
          value: 'What was the first thing you learned to cook?'
        },
        {
          text: this.$t('invite.question-job'),
          value: 'What was your dream job as a child?'
        },
        {
          text: this.$t('invite.question-spouse'),
          value: 'Where did you meet your spouse/significant other?'
        },
        {
          text: this.$t('invite.question-vacation'),
          value: 'Where did you go for your favorite vacation?'
        },
        {
          text: this.$t('invite.question-eve'),
          value: 'Where were you on New Year\'s Eve in the year 2000?'
        },
        {
          text: this.$t('invite.question-speaker'),
          value: 'Who is your favorite speaker/orator?'
        },
        {
          text: this.$t('invite.question-character'),
          value: 'Who is your favorite book/movie character?'
        },
        {
          text: this.$t('invite.question-player'),
          value: 'Who is your favorite sports player?'
        }
      ]
    }
  },
  methods: {
    validate() {
      if (this.changePassword && !this.finalized.changePassword) {
        this.validation.current = !this.passwordCurrent.trim().length
          ? `${this.$t('changePassword.currentPassword')} ${this.$t(
            'validation.cannotBeEmpty'
          )}`
          : false

        this.validation.new = hasWhiteSpace(this.passwordNew)
          ? `${this.$t('changePassword.newPassword')} ${this.$t(
            'validation.cannotHaveWhitespaces'
          )}`
          : false
        this.validation.new = !this.passwordNew.trim().length
          ? `${this.$t('changePassword.newPassword')} ${this.$t(
            'validation.cannotBeEmpty'
          )}`
          : this.validation.new

        this.validation.confirm =
          this.passwordNew != this.passwordConfirm
            ? this.$t('validation.notIdentical')
            : false
        this.validation.confirm = !this.passwordConfirm.trim().length
          ? `${this.$t('changePassword.confirmNewPassword')} ${this.$t(
            'validation.cannotBeEmpty'
          )}`
          : this.validation.confirm
      }
      if (this.changeSecurityQuestion && !this.finalized.changeSecurityQuestion) {
        this.validation.recoveryAnswer =
          this.recoveryAnswer === ''
            ? `${this.$t('invite.recoveryAnswer')} ${this.$t(
              'invite.validation-empty'
            )}`
            : false
      }

      return !Object.values(this.validation).some(x => x != false)
    },
    async sendRequest(e) {
      e.preventDefault()
      if (!this.validate()) return

      const requestConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokens.accessToken}`
        }
      }

      this.$emit('toggleLoad')

      if (this.changePassword && !this.finalized.changePassword) {
        const requestBody = {
          oldPassword: this.passwordCurrent,
          newPassword: this.passwordNew
        }

        await http
          .post(apiUrls.IAM_OUTER_CHANGE_PASSWORD, requestBody, requestConfig)
          .then(() => {
            this.finalized.changePassword = true
            this.changePassword = false
          })
          .catch(r => {
            const err = r.response.data?.error?.message || this.$t('common.undefinedError')
            this.validation.confirm = err
          })
      }

      if (this.changeSecurityQuestion && !this.finalized.changeSecurityQuestion) {
        const requestBody = {
          question: this.recoveryQuestionSelected,
          answer: this.recoveryAnswer
        }

        await http
          .post(apiUrls.IAM_OUTER_CHANGE_SECURITY_QUESTION, requestBody, requestConfig)
          .then(() => {
            this.finalized.changeSecurityQuestion = true
            this.changeSecurityQuestion = false
          })
          .catch(r => {
            const err = r.response.data?.error?.message || this.$t('common.undefinedError')
            this.validation.recoveryAnswer = err
          })
      }

      this.$emit('toggleLoad')
    }
  }
}
</script>
