<template>
  <b-overlay class="spinner-top"
             no-fade
             rounded
             :show="!authenticated || loading"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
  >
    <FormError v-if="error.present" :error="error.text" mode="infobox" />

    <section v-if="!latestVersionAccepted" class="help-container text-center custom-form">
      <h2 data-se="o-form-head" class="okta-form-toc-title o-form-head text-capitalize">{{ $t('toc.pleaseReview') }}</h2>
      <ul class="list-inline-item text-left okta-form-toc-list">
        <li v-for="item in tocUrls" :key="item" v-html="item" />
      </ul>
      <b-container>
        <b-row class="justify-content-center mb-4">
          <b-col cols="auto">
            <b-form-checkbox v-model="tocAccepted"
                             size="lg"
            >
              <span class="ml-2 pt-1">{{ $t('toc.accept') }}</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section v-else class="help-container text-center">
      <h2 data-se="o-form-head" class="okta-form-toc-title o-form-head text-capitalize">{{ $t('toc.tocAccepted') }}</h2>
      <ul class="list-inline-item text-left okta-form-toc-list">
        <li v-for="item in tocUrls" :key="item" v-html="item" />
      </ul>
    </section>

    <div class="clearfix">
      <div class="o-form-button-bar mt-0">
        <b-button class="button button-primary"
                  type="button"
                  :disabled="!latestVersionAccepted && !tocAccepted"
                  @click="postAcceptance"
        >
          {{ $t('common.continue') }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {iamService} from '@/services/iamService'
import {contentService} from '@/services/contentService'
import FormError from '@/components/helpers/FormError.vue'
import {getUrlParam, redirectUser, getLanguage} from '@/utils'

export default {
  components: {
    FormError
  },
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      error: {
        present: false,
        text: null
      },
      tocAccepted: false,
      tocCheckboxText: 'I accept',
      tocUrls: [],
      redirectUrl: getUrlParam('redirect_uri') || '/',
      application: getUrlParam('application') || '',
      forcedLanguage: getLanguage(),
      latestVersionAccepted: false,
      packageVersion: null
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    },
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    tokens: {
      deep: true,
      handler() {
        this.init()
      }
    },
    locale () {
      if(this.forcedLanguage == this.locale) return
      this.forcedLanguage = this.locale
      this.init()
    }
  },
  methods: {
    init() {
      if(this.tokens && this.tokens.accessToken) this.getAcceptanceStatus()
    },
    postAcceptance() {
      if(this.latestVersionAccepted) {
        redirectUser(this.redirectUrl)
        return
      }

      this.loading = true
      iamService.postTocAcceptance(this.requestConfig, {
        packageVersion: this.packageVersion,
        ...(this.application && {application: this.application})
      }).then(() => {
        this.latestVersionAccepted = true
        redirectUser(this.redirectUrl)
      }).catch(r => {
        this.error.present = true
        this.error = r.response?.data?.error?.message || this.$t('common.undefinedError')
      }).finally(() => {
        this.loading = false
      })
    },
    getAcceptanceStatus() {
      this.error.present = false
      iamService.fetchTocAcceptance(this.requestConfig, this.application).then(r => {
        this.latestVersionAccepted = r.data.isLatestVersionAccepted
        this.application = r.data.application ? r.data.application : ''
        this.getTocTextForBrandRegionAndChannel(r.data.brand, r.data.region, r.data.channel)
      }).catch(r => {
        this.error.present = true
        this.error = r.response?.data?.error?.message || this.$t('common.undefinedError')
        this.loading = false
      })
    },
    getTocTextForBrandRegionAndChannel(brand, region, channel) {
      const replaceRegex = new RegExp(`-(${this.forcedLanguage})$`, 'g')
      contentService.fetchTocPackage(brand.toLowerCase(), region.toLowerCase(), channel.toLowerCase(), this.application, this.forcedLanguage).then(r => {
        this.tocUrls = r.data.documents.map(d => {
          const filename = d.filename.replace(replaceRegex, '')
          const baseUrl = `/app/toc/${brand.toLowerCase()}/${region.toLowerCase()}/${channel.toLowerCase()}`
          const app = `${this.application !== '' ? this.application + '/' : ''}`
          const url = `${baseUrl}/${app}${filename}?lang=${this.forcedLanguage}`
          return `<a href="${url}" target="_blank">${d.title}</a>`
        })
        this.packageVersion = r.data.version
      }).catch(() => {
        this.error = `${this.$t('toc.fetchingError')} ${region}`
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
