<template>
  <section class="help-container">
    <h3 class="okta-form-title o-form-head font-condensed" data-se="o-form-head">
      {{ $t('mfaEmail.title') }}
    </h3>
    <b-overlay
      :blur="$root.globalConfig.overlay.blur"
      :opacity="$root.globalConfig.overlay.opacity"
      :show="!user || loading"
      :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
      :variant="$root.globalConfig.overlay.variant"
      no-fade
      rounded
      z-index="100"
    >
      <div v-if="codeSent">
        <div class="clearfix mb-4">
          <FormError :error="validation.userEmail" mode="infobox" />
          <div class="mfa-send-email-content" data-se="mfa-send-email-content">
            <p class="text-center">{{ $t('mfaEmail.description') }} <strong>{{ obfuscatedUserEmail }}</strong></p>
            <br><br>
          </div>
          <b-button class="button button-primary" type="submit" style="width: 100%;" @click="sendCode">{{ $t('mfaEmail.sendCode') }}</b-button>
          <b-button
            class="button button-secondary text-primary mt-4"
            type="button"
            @click="goBack()"
          >
            {{ $t('common.goBack') }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="verificationCode-input form-error-row">
          <FormError :error="validation.verificationCode" mode="infobox" />
          <div class="mfa-send-email-content" data-se="mfa-send-email-content">
            {{ $t('mfaEmail.codeDescription') + ' ' }} <strong>{{ obfuscatedUserEmail }}</strong> {{ '. ' + $t('mfaEmail.codeDescriptionPartTwo') }}
            <br><br>
          </div>
          <div v-if="resendPromptVisible" class="resend-code">
            <div class="infobox infobox-warning" role="alert">
              <span class="icon warning-16" />
              <p>{{ $t('mfaEmail.resendCode') }} <a href="#" @click="resendPasscode"> {{ $t('mfaEmail.sendAgain') }} </a></p>
            </div>
          </div>

          <label for="verificationCode" class="o-form-label">{{ $t('mfaEmail.verificationCode') }}</label>
          <b-form-input id="verificationCode" v-model="verificationCode" @change="hideWarning" />
          <br>
          <b-button class="button button-primary" type="submit" style="width: 100%;" @click="verifyPassCode">{{ $t('mfaEmail.verify') }}</b-button>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>

import {iamService} from '@/services/iamService'
import {obfuscateEmail} from '@/utils'
import FormError from '@/components/helpers/FormError.vue'

const FACTOR_TYPE = 'email'
const STATUS_PENDING_ACTIVATION = 'PENDING_ACTIVATION'
const STATUS_ACTIVE = 'ACTIVE'
const MILIS_TO_WAIT_BEFORE_RESEND = 35000

export default {
  components: {
    FormError
  },
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      obfuscatedUserEmail: '',
      verificationCode: '',
      userEmail: '',
      codeSent: true,
      resendPromptVisible: false,
      validation: {
        verificationCode: false,
        userEmail: false
      },
      timer: false,
      factorId: ''
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  beforeDestroy() {
    this.clearResendTimer()
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      if (this.$route.meta.requiresAuth) {
        await this.setupTokens()
      }
      this.user = await this.getUser()
      this.obfuscatedUserEmail = obfuscateEmail(this.user.email)
    },
    async getUser () {
      const user = await this.$auth.getUser()
      return user ? user : null
    },
    async setupTokens() {
      this.authenticated = await this.$auth.isAuthenticated()
      this.loading = false
      if (!this.authenticated) return
      this.tokens.accessToken = await this.$auth.getAccessToken()
      this.loading = false
    },
    clearResendTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = false
    },
    validatePassCode(passCode) {
      return !!passCode && !!passCode.length
    },
    translateEnrollErrorMessage(message) {
      if (message.includes('already set up') || message.includes('400')) {
        return this.$t('mfaEmail.alreadySetUp')
      } else if (message.includes('403')) {
        return this.$t('mfaEmail.unauthorized')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else {
        return this.$t('mfaEmail.error')
      }
    },
    translateActivateErrorMessage(message) {
      if (message.includes('400')){
        return this.$t('mfaEmail.invalidCode')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else if (message.includes('Invalid') || message.includes('403')) {
        return this.$t('mfaEmail.invalidCode')
      } else {
        return this.$t('mfaEmail.error')
      }
    },
    hideWarning() {
      this.codeSent = false
      this.resendPromptVisible = false
      this.waitAndShowResendPrompt()
    },
    waitAndShowResendPrompt() {
      this.clearResendTimer()
      this.timer = setInterval(() => {
        this.resendPromptVisible = true
        clearInterval(this.timer)
      }, MILIS_TO_WAIT_BEFORE_RESEND)
    },
    async resendPasscode() {
      await iamService.resendMfaChallenge(this.factorId, FACTOR_TYPE, this.requestConfig)
      this.resendPromptVisible = false
      this.waitAndShowResendPrompt()
    },
    async sendCode() {
      try {
        if (!this.codeSent) {
          return
        }
        this.validation.userEmail = ''
        const userEmail = {
          'email': this.user.email
        }
        const response = await iamService.enrollUserToMfaFactor(FACTOR_TYPE, userEmail, this.requestConfig)
        if (response.data._value.status === STATUS_PENDING_ACTIVATION) {
          this.factorId = response.data._value.id
          this.codeSent = false
          this.waitAndShowResendPrompt()
        }
        else if (response.data._value.status === STATUS_ACTIVE) {
          this.goBack()
        }
      } catch(e) {
        this.validation.userEmail = this.translateEnrollErrorMessage(e.message)
      }
    },
    async verifyPassCode() {
      try {
        this.validation.verificationCode = ''
        const code = this.verificationCode
        if (!this.validatePassCode(code)) {
          this.validation.verificationCode = this.$t('mfaEmail.invalidCode')
          return
        }
        await iamService.activateMfaFactor(this.factorId, code, this.requestConfig)
        this.validation.verificationCode = ''
        this.goBack()
      } catch(e) {
        this.validation.verificationCode = this.translateActivateErrorMessage(e.message)
      }
    },
    goBack() {
      this.$router.push('/app/mfa-settings')
    }

  }
}
</script>

<style lang="scss" scoped>
  button {
    width: 100%;
  }
</style>
