<template>
  <section class="help-container">
    <b-overlay :show="selectedAccount === '' && errorMsg === ''"
               no-fade
               rounded
               z-index="100"
               :blur="$root.globalConfig.overlay.blur"
               :opacity="$root.globalConfig.overlay.opacity"
               :variant="$root.globalConfig.overlay.variant"
               :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
    >
      <div v-if="selectedAccount !== '' && errorMsg === ''">
        <h2 data-se="o-form-head" class="okta-form-toc-title text-capitalize" :style="{'padding-top': '0'}">{{ $t('tra.iStoreSelectAccount') }}</h2>
        <b-form-select v-model="selectedAccount">
          <option v-for="account in accounts" :key="account.id" :value="account.value">
            {{ account.text }}
          </option>
        </b-form-select>
        <br><br>
        <b-button class="button button-primary" type="button" @click="redirectToTraApp()">
          {{ $t('common.continue') }}
        </b-button>
      </div>
      <div v-if="errorMsg !==''">
        <p>{{ errorMsg }}</p>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {auroraService} from '@/services/auroraService'
import config from '@/config'

export default {
  data() {
    return {
      tokens: Object,
      selectedAccount: '',
      accounts: [],
      errorMsg: '',
      traAppUrl: config.traAppUrl
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if(this.$route.meta.requiresAuth) {
        await this.setupTokens()
        await this.setupAccounts()
        this.tryToRedirect()
      }
    },
    async setupTokens () {
      this.authenticated = await this.$auth.isAuthenticated()
      if(!this.authenticated) return
      this.tokens.accessToken = await this.$auth.getAccessToken()
    },
    async fetchData() {
      let identity = await auroraService
        .fetchPassportIdentity(this.requestConfig)
        .catch(() => {
          this.showFetchIdentityError()
        })
      identity = JSON.parse(JSON.stringify(identity))
      return identity?.data?.diaUid
    },
    async setupAccounts() {
      let accountsList = await this.fetchData()
      if(accountsList) {
        accountsList = accountsList.split(';')
        Object.entries(accountsList).forEach(([, value]) => {
          let accData = value.split(':')
          if(accData.length > 1) {
            this.accounts.push({'text': accData[1] + ' (' + accData[0] + ')', 'value': accData[0], 'id': value})
          } else {
            this.accounts.push({'text': accData[0], 'value': accData[0], 'id': value})
          }
        })
      } else {
        this.showSetAccountsError()
      }
    },
    tryToRedirect() {
      if(this.accounts.length === 1) {
        this.redirectToTraApp(this.accounts[0].value)
      } else {
        this.selectedAccount = this.accounts[0].value
      }
    },
    redirectToTraApp(account) {
      let iStoreAccount = account ? account : this.selectedAccount
      if(iStoreAccount !== '') {
        location.href = `${this.traAppUrl}/istore/login?mode=HMAC-SHA1&iStoreAccount=${iStoreAccount}`
      }
    },
    showSetAccountsError() {
      this.errorMsg = 'There is no iStore account assigned for the user.'
    },
    showFetchIdentityError() {
      this.errorMsg = 'Unable to fetch identity data for the user.'
    }
  }
}
</script>

<style>
</style>
