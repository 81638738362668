import http from '@/utils'
import apiUrls from '@/api/apiUrls'
import qs from 'qs'

export const auroraService = {
  fetchPassportIdentity,
  fetchOrgDescendants,
  searchOrganizations
}

async function fetchPassportIdentity(requestConfig) {
  return http.get(apiUrls.PASSPORT_AURORA_API_IDENTITY, requestConfig)
}

async function fetchOrgDescendants(requestConfig) {
  return http.get(apiUrls.PASSPORT_AURORA_API_DESCENDANTS, requestConfig)
}

async function searchOrganizations(requestConfig, sortByField = 'name', order = 'DESC', offset = 0, limit = 25, name = 'bobcat') {
  return http.get(`${apiUrls.PASSPORT_AURORA_API_SEARCH}?${qs.stringify({sortByField, order, offset, limit, name})}`, requestConfig)
}
