<template>
  <div class="d-block d-md-inline">
    <font-awesome-icon icon="globe-americas" class="globe-icon" :class="selector_active?'text-black':'text-white'" />
    <b-form-select v-model="$i18n.locale"
                   :options="options"
                   class="border-0"
                   @focus.native="selector_active = true"
                   @blur.native="selector_active = false"
                   @change="changeLang"
    />
  </div>
</template>

<script>
import {i18n, loadLanguageAsync, SUPPORTED_LOCALES} from '@/i18n'

export default {
  data() {
    return {
      selector_active: false,
      selected: i18n.locale,
      options: Object.keys(SUPPORTED_LOCALES).map(key => {
        return {value: key, text: SUPPORTED_LOCALES[key]}
      }),
      localesCopy: {...SUPPORTED_LOCALES}
    }
  },
  updated() {
    if(JSON.stringify(this.localesCopy) !== JSON.stringify(SUPPORTED_LOCALES)) {
      this.options = Object.keys(SUPPORTED_LOCALES).map(key => {
        return {value: key, text: SUPPORTED_LOCALES[key]}
      })
      this.localesCopy = {...SUPPORTED_LOCALES}
    }
  },
  methods: {
    changeLang(val){
      loadLanguageAsync(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.globe-icon {
  position: relative;
  left: 1.5rem;
  top: 0.075em;
}
.custom-select {
  background: #333 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: none;
  color: white;
  font-family: "Helvetica Neue Bold Condensed";
  padding-left: 2rem;
  width: auto;
  min-width: 1rem;
  &:focus {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    box-shadow: none;
    background-color: snow;
    color: black;
  }
}
</style>
