<template>
  <section class="help-container">
    <h3 data-se="o-form-head" class="okta-form-title o-form-head">
      {{ $t('changePassword.dialogTitle') }}
    </h3>
    <b-overlay
      :show="!user || loading"
      no-fade
      rounded
      z-index="100"
      :blur="$root.globalConfig.overlay.blur"
      :opacity="$root.globalConfig.overlay.opacity"
      :variant="$root.globalConfig.overlay.variant"
      :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
    >
      <div v-if="(userDirectory=='customer' || userDirectory=='dealer')" class="clearfix">
        <my-account-component-form :tokens="tokens" @toggleLoad="toggleLoad" />
      </div>
      <div v-if="userDirectory == 'internal'" class="clearfix mb-3">
        <p v-html="this.$t('changePassword.adAccountResponse')" />
      </div>
      <hr>
      <div class="clearfix mb-3">
        <h3 class="text-center text-primary custom-form__header" role="button">
          <a href="/app/mfa-settings" class="text-decoration-none">
            <span v-html="this.$t('mfaSettings.pageUrlTitle')" />
          </a>
        </h3>
      </div>
      <div class="o-form-button-bar">
        <b-button
          class="button button-secondary text-primary"
          type="button"
          @click="goBack()"
        >
          {{ $t('common.goBack') }}
        </b-button>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {getUrlParam, redirectUser} from '@/utils'
import MyAccountComponentForm from './MyAccountComponentForm.vue'

export default {
  components: {MyAccountComponentForm},
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      redirectUrl: getUrlParam('redirect_uri') || '/',
      userTypes: ['customer', 'internal', 'dealer']
    }
  },
  computed: {
    userDirectory() {
      if (!this.user) return false
      const groupFound = this.user.directory_group.find(el =>
        this.userTypes.includes(this.getDirectoryGroup(el))
      )
      return groupFound ? this.getDirectoryGroup(groupFound) : false
    }
  },
  methods: {
    goBack() {
      this.redirectUrl === '/'
        ? this.$router.push('/app/dashboard')
        : redirectUser(this.redirectUrl)
    },
    getDirectoryGroup(dir) {
      return dir.substring(dir.indexOf('-') + 1, dir.length)
    },
    toggleLoad() {
      this.loading = !this.loading
    }
  }
}
</script>
<style lang="scss">
  @import "./../scss/login_brands";

  .ryan, .geith, .steiner {
    a {
      text-transform: lowercase;
      font-weight: bold;
    }
    span {
      text-transform: uppercase;
    }
  }

  #footer {
    span {
      text-transform: none;
    }
  }
</style>