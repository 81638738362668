import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@/config'
import {getUrlParam} from '@/utils'
import {contentService} from '@/services/contentService'
import {fetchAvailableLanguages} from '@/services/publicContentService'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: config.fallbackLanguage,
  fallbackLocale: config.fallbackLanguage,
  messages: {}
})

export let SUPPORTED_LOCALES = {
}

const loadedLanguages = []
let detectedUserRegion = false
let fallbackLanguageLoading = false

const oktaCustomLanguageMapping = {
  nl: 'nl-NL'
}

export function mapContentLanguageToOktaCustomLanguage(lang) {
  const mappedLang = oktaCustomLanguageMapping[lang]
  return mappedLang || lang
}

export function mapOktaCustomLanguageToContentLanguage(lang) {
  const mappedLang = Object.keys(oktaCustomLanguageMapping).find(key => oktaCustomLanguageMapping[key] === lang)
  return mappedLang || lang
}

export function getLanguageDisplayName(lang) {
  const displayNames = new Intl.DisplayNames([lang], {type: 'language'})
  const displayName = displayNames.of(lang)
  return displayName.charAt(0).toUpperCase() + displayName.slice(1).toLowerCase()
}

function setI18nLanguage (lang) {
  localStorage.setItem('dxpSelectedLanguage', lang)
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', mapContentLanguageToOktaCustomLanguage(lang))
  return lang
}

export async function applyLanguageToApplication () {
  const response = await fetchAvailableLanguages()
  SUPPORTED_LOCALES = response.reduce((acc, cur) => {
    acc[cur] = getLanguageDisplayName(cur)
    return acc
  }, {})
  const langParam = getUrlParam('lang') || localStorage.getItem('dxpSelectedLanguage') || false
  if (langParam) {
    loadLanguageAsync(langParam)
  }
  else {
    navigatorCloseLanguage()
  }
}

export function navigatorCloseLanguage () {
  const userLanguage = navigator.language
  if (userLanguage.includes('-')) {
    const userLanguageSplit = userLanguage.split('-')
    return loadLanguageAsync(userLanguageSplit[0])
  }
  return loadLanguageAsync(userLanguage)
}

export async function loadLanguageAsync (lang) {
  if (i18n.locale === lang && i18n.messages[lang]) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (!SUPPORTED_LOCALES[lang]) {
    return loadLanguageAsync(config.fallbackLanguage)
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // preventing double call of en version endpoint
  if(lang === config.fallbackLanguage) {
    if(!fallbackLanguageLoading) fallbackLanguageLoading = true
    else return
  }

  if(!detectedUserRegion) {
    await contentService.fetchRegion().then(r => {
      detectedUserRegion = r.data?.region?.toLowerCase() || ''
    })
  }

  let regionTranslations = {}
  if(detectedUserRegion && detectedUserRegion !== '')
    await contentService.fetchTranslations( lang, detectedUserRegion).then( res => {
      regionTranslations = {...res.data}
    }).catch(() => {})

  return contentService.fetchTranslations(lang).then(res => {
    const translations = res.data

    Object.keys(regionTranslations).forEach(key => {
      if(translations[key]) translations[key] = {...translations[key], ...regionTranslations[key]}
    })

    i18n.setLocaleMessage(lang, translations)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  }).catch(() => {
    i18n.setLocaleMessage(lang, {})
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

