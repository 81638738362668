<template>
  <div class="container text-center">
    <div class="header">
      <img :src="`/img/header/${brand}.png`" :alt="`${brand} logo`" class="org-logo">
    </div>
    <div class="content">
      <h1 id="error_code" class="o-form-title mt-3">{{ errorCode }}</h1>
      <h2 id="error" class="o-form-title">{{ errorSummary }}</h2>
      <p id="error_description" class="o-form-explain my-4">
        {{ errorDescription }}
      </p>
      <div class="o-form-button-bar">
        <b-button id="back" class="button button-primary" @click="goBack()">
          {{
            $t("common.goBack")
          }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getUrlParam} from '@/utils'

export default {
  name: 'Error',
  data () {
    return {
      brand: process.env.VUE_APP_BRAND,
      errorCode: '404',
      errorSummary: 'PAGE NOT FOUND',
      errorDescription: 'We couldn\'t find the page you were looking for.',
      clientId: getUrlParam('client_id')
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.brand === 'bobcat' && this.clientId) {
        this.redirectToRequestAccess()
      }
      this.findAndReplaceGetParameter('error_code')
      this.findAndReplaceGetParameter('error')
      this.findAndReplaceGetParameter('error_description')
    },
    redirectToRequestAccess() {
      this.$router.push({path: '/app/request-access', query: {client_id: this.clientId}})
    },
    goBack() {
      window.location.pathname = '/'
    },
    findAndReplaceGetParameter(parameterName) {
      let result = getUrlParam(parameterName)
      if (!result) return

      result = result.split('+').join(' ')
      result = result.split('_').join(' ')
      result = result.charAt(0).toUpperCase() + result.substring(1)

      const element = document.getElementById(parameterName)
      if (element) element.innerText = result

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
#error_code {
  font-size: 1.625rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  font-weight: bold;
}

#error {
  font-size: 1rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1 !important;
}

#error_description {
  font-size: 1rem !important;
}

#back {
  width: 100% !important;
}
</style>
