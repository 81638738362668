<template>
  <b-overlay class="spinner-top flex-grow-1 flex-shrink-0  free-component"
             no-fade
             rounded
             :show="!authenticated || loading"
             :blur="$root.globalConfig.overlay.blur"
             :opacity="$root.globalConfig.overlay.opacity"
             :variant="$root.globalConfig.overlay.variant"
             :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
  >
    <div class="flex-grow-1 flex-shrink-0">
      <div class="text-center mb-4">
        <h2 data-se="o-form-head" class="okta-form-title o-form-head text-capitalize ">{{ $t('dashboard.myApplications') }}</h2>
        <b-container class="application-container mx-auto">
          <b-row v-if="apps.length > 8">
            <b-col class="p-2">
              <b-form-input v-model="appFilter" :placeholder="this.$t('dashboard.filterByName')" />
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center mb-4">
            <b-col v-for="app in filteredApps" :key="app.id" md="4" sm="12" class="p-2">
              <ApplicationCard :app="app" />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ApplicationCard from '@/components/helpers/ApplicationCard'
import {iamService} from '@/services/iamService'
import oktaConfig from '@/config'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import OktaJwtVerifier from '@okta/jwt-verifier'

Vue.use(VueCookies)

export default {
  components: {
    ApplicationCard
  },
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      appFilter: '',
      isAdmin: false,
      loading: true,
      apps: [],
      adminApp: {
        'label': 'ADMIN',
        'status': 'ACTIVE',
        '_links': {
          'appLinks': [
            {
              'href': '/home/admin-entry'
            }
          ],
          'logo': [
            {
              'name': 'medium',
              'href': 'https://www.okta.com/themes/custom/okta_www_theme/images/logo.svg'
            }
          ]
        }
      }
    }
  },
  computed: {
    tokensReady() {
      return this.tokens && this.tokens.accessToken
    },
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    },
    filteredApps() {
      const filtered = this.apps.filter(app =>
        (this.appFilter == '' || app.label.toLowerCase().indexOf(this.appFilter.toLowerCase()) != -1)
        && app.status == 'ACTIVE')
        .sort((a, b) => a.label < b.label ? -1 : 1)

      return this.isAdmin ? [this.adminApp, ...filtered] : filtered
    },
    oktaJwtVerifier() {
      return new OktaJwtVerifier({
        issuer: `${oktaConfig.oktaOrgUrl}/oauth2/${oktaConfig.authorizationServer}`,
        clientId: this.retrieveBrandedClientId()
      })
    }
  },
  watch: {
    tokensReady: {
      handler() {
        this.init()
      }
    }
  },
  created() {
    if(this.tokensReady) this.init()
  },
  methods: {
    async init() {
      await this.validateTokens()
      this.getAppList()
      this.checkForAdmin()
    },
    async validateTokens() {
      if (this.tokens && this.tokens.accessToken) {
        const validTokens = await this.isValidAccessToken()
        if (!validTokens) {
          this.$emit('on-logout')
        }
      }
    },
    retrieveBrandedClientId() {
      return window.location.origin.includes('geith') ? oktaConfig.geithClientId : oktaConfig.bobcatClientId
    },
    async isValidAccessToken() {
      try {
        await this.oktaJwtVerifier.verifyAccessToken(this.tokens.accessToken, oktaConfig.jwtAudClaim)
        return true
      } catch (err) {
        //Access token is not valid
        return false
      }
    },
    getAppList() {
      iamService.fetchUserApps(this.requestConfig).then(r => {
        this.apps = r.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    checkForAdmin() {
      iamService.fetchOktaUserInternal()
        .then(r => { this.isAdmin = r.data.admin })
        .catch(() => {
          this.isAdmin = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../scss/grid';

  @include media-breakpoint-up('lg') {
    .application-container {
      max-width: 50%;
    }
  }
</style>
