<template>
  <b-navbar-nav class="to-right align-items-center bg-dark h-100 mr-0 p-3">
    <div class="cursor-pointer" @click="toggleProfile">
      <div class="position-relative d-inline-block">
        <b-avatar variant="primary" class="mr-2" />
        <span class="text-white d-none d-md-inline-block">{{ user.name }}
          <b-icon-caret-down-fill v-if="!isOpened" />
          <b-icon-caret-up-fill v-else />
        </span>
        <span id="uid" class="d-none">{{ user.sub }}</span>
      </div>
    </div>

    <transition name="fade">
      <div v-show="isOpened" id="menu-profile" class="menu-profile bg-dark py-3">
        <b-col cols="12" tag="ul" class="list-unstyled font-condensed">
          <b-nav-item class="d-xs-block d-md-none position-relative">
            <span class="ml-2 text-white">{{ user.name }}</span>
          </b-nav-item>
          <b-nav-item class="bg-xs-black position-relative"
                      to="/app/dashboard"
                      @click="toggleProfile"
          >
            <span class="ml-2 text-white">{{ $t('dashboard.title') }}</span>
          </b-nav-item>
          <b-nav-item class="bg-xs-black position-relative"
                      to="/app/my-account"
                      @click="toggleProfile"
          >
            <span class="ml-2 text-white">{{ $t('changePassword.title') }}</span>
          </b-nav-item>
          <b-nav-item class="bg-xs-black position-relative"
                      @click="logout"
          >
            <span class="ml-2 text-white">{{ $t('common.logout') }}</span>
          </b-nav-item>
        </b-col>
      </div>
    </transition>

    <div v-show="isOpened"
         class="modal-backdrop"
         style="z-index: -1;"
         @click="toggleProfile"
    />
  </b-navbar-nav>
</template>

<script>
import {BIconCaretDownFill, BIconCaretUpFill} from 'bootstrap-vue'

export default {
  components: {
    BIconCaretDownFill,
    BIconCaretUpFill
  },
  props: {
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isOpened: false
    }
  },
  methods: {
    toggleProfile() {
      this.isOpened = !this.isOpened
    },
    logout() {
      this.$emit('on-logout')
    }
  }
}
</script>

<style lang="scss">
  @import '../../scss/colors';
  @import '../../scss/grid';

  .fade-enter-active, .fade-leave-active, .modal-backdrop {
    transition: all .35s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }


  .menu-profile {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 4.5rem;
    z-index: 999;

    @include media-breakpoint-up('md') {
      left: auto;
      width: 18rem;
    }

    &_opened {
      body {
        height: 100vh;
        overflow: hidden;
      }
    }

    .nav-item {
      &:hover {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 0.25rem;
          background-color: $orange;
          z-index: -1;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .bg-xs-black {
      background-color: $black;
    }
  }
</style>
