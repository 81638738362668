import axios from 'axios'
import EmailValidator from 'email-validator'

const _http = axios.create()
export default _http

export function getUrlParam(name) {
  let results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
    window.location.href
  )
  return results != null ? decodeURIComponent(results[1]) || 0 : null
}

export function toEmailFormat(string, emailSuffix) {
  return EmailValidator.validate(string)
    ? string
    : string.replace(/\s/g, '').toLowerCase() + emailSuffix
}

export function hasWhiteSpace(s) {
  return /\s/g.test(s)
}

export function transformApplicationName(text) {
  return text
    .toLowerCase()
    .replaceAll(/\((dev|qa|prod)\)/gm, '')
    .trim()
    .replaceAll(' ', '-')
}

export function changeTitleIntoUrl(text) {
  return text
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9|^/]/g, '-')
}

export function transformArrayIntoDividedText(list) {
  if (list.length)
    return list.reduce((previous, current, i, array) => {
      if (i === array.length - 1) return previous + ' & ' + current
      else return previous + ', ' + current
    })
  return ''
}

export function redirectUser(url) {
  window.location.href = url
}

const DEFAULT_LOCALE = 'en'

export function getLanguage(forced = false) {
  return (
    forced ||
    localStorage.getItem('dxpSelectedLanguage') ||
    getBrowserLocale() ||
    DEFAULT_LOCALE
  )
}

export function getBrowserLocale() {
  return navigator.language.split('-')[0]
}

export function isValidBrandName(str) {
  return str.toLowerCase().match(/^(bobcat|ryan|portablepower|geith|steiner)$/)
}

export function isEmpty(str) {
  return (!str || str.length === 0)
}

export function obfuscateEmail(email) {
  const [localPart, domain] = email.split('@')
  return localPart[0] + '...' + localPart[localPart.length - 1] + '@' + domain
}

export function isDevApplication(redirectUri) {
  return /\.dev\./gm.test(redirectUri)
}

const INTERNAL_DIRECTORY_GROUP = 'directory-internal'

export function isInternalUser(directoryGroups) {
  return directoryGroups.includes(INTERNAL_DIRECTORY_GROUP)
}
