import http, {getLanguage} from '@/utils'
import apiUrls from '@/api/apiUrls'

export const contentService = {
  fetchConceptFolder,
  fetchTocPackage,
  fetchConcept,
  fetchCountries,
  fetchRegion,
  fetchTocDocument,
  fetchTranslations,
  fetchBrandInfo,
  fetchDevBrandInfo
}

async function fetchConceptFolder(path) {
  return http.get(apiUrls.CONTENT_ACCOUNT_COMMON.replace('{path}', path) + '?lang=' + getLanguage())
}

async function fetchTranslations(lang, region = 'common') {
  return http.get(apiUrls.CONTENT_TRANSLATIONS.replace('{path}', region).replace('{lang}', lang))
}

async function fetchCountries(disableLocationServices = false) {
  return http.get(apiUrls.CONTENT_LOCATIONS.replace('{disableLocationServices}', disableLocationServices))
}

async function fetchRegion() {
  return http.get(apiUrls.CONTENT_LOCATIONS_DETECTED)
}

async function fetchBrandInfo(oktaApplicationId) {
  return http.get(apiUrls.CONTENT_BRAND_INFO.replace('{application}', oktaApplicationId))
}

async function fetchDevBrandInfo(oktaApplicationId) {
  return http.get(apiUrls.CONTENT_DEV_BRAND_INFO.replace('{application}', oktaApplicationId))
}

async function fetchConcept(path, conceptName = null) {
  let {data} = await fetchConceptFolder(path)
  return conceptName ? data.filter(item => item.key === conceptName)[0] : data //there will only be 1 node with that name
}

async function fetchTocPackage(brand, region, channel, application = '', forcedLanguage = false) {
  const url = application !== '' ? apiUrls.CONTENT_TOC_APP.replace('{application}', application) : apiUrls.CONTENT_TOC

  return http.get(url
    .replace('{brand}', brand)
    .replace('{region}', region)
    .replace('{channel}', channel)
    + '&lang=' + getLanguage(forcedLanguage))
    .catch(() => {
      if(application !== '') return fetchTocPackage(brand, region, channel, '', forcedLanguage)
    })
}

async function fetchTocDocument(brand, region, channel, application, filename, language = false) {
  const tocPackage = await fetchTocPackage(brand, region, channel, application, language)

  const document = tocPackage.data.documents.filter(item =>
    item.filename.toUpperCase() === (filename + '-' + language).toUpperCase() ||
    item.filename.toUpperCase() === (filename + '-en').toUpperCase() ||
    item.filename.toUpperCase() === filename.toUpperCase()
  )

  if (!document[0]) throw new Error('Document does not exist')

  return http.get(apiUrls.CONTENT_API_ROOT + '/' + document[0].location + '?lang=' + language).then(r => { return {...r, title: document[0].title} })
}
