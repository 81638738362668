const {VUE_APP_OKTAORG_URI,
  VUE_APP_PASSPORT_AURORA_API_URI,
  VUE_APP_TRA_APP_URI,
  VUE_APP_OUTER_IAM_API_URI,
  VUE_APP_CONTENT_API_URI,
  VUE_APP_PUBLIC_CONTENT_API_URI,
  VUE_APP_DEV_PUBLIC_CONTENT_API_URI,
  VUE_APP_REDIRECT_URI,
  VUE_APP_PASSWORD_URI,
  VUE_APP_AUTHORIZATION_SERVER,
  VUE_APP_HELP_URI,
  VUE_APP_PRIVACY_POLICY_URI,
  VUE_APP_AD_EMAIL_SUFFIX,
  VUE_APP_OKTA_HOSTED_URI,
  VUE_APP_OKTA_GEITH_HOSTED_URI,
  VUE_APP_OKTA_HOSTED_AUTHORIZATION_SERVER,
  VUE_APP_GEITH_CLIENT_ID,
  VUE_APP_BOBCAT_CLIENT_ID,
  VUE_APP_FALLBACK_LANGUAGE,
  VUE_APP_BRAND,
  VUE_APP_JWT_AUD_CLAIM,
  VUE_APP_NG_PASSPORT_URI,
  VUE_APP_DEV_NG_PASSPORT_URI,
  VUE_APP_DSA_URL,
  VUE_APP_TOKENMANAGER_KEY
} = process.env

export default {
  oktaOrgUrl: VUE_APP_OKTAORG_URI,
  traAppUrl: VUE_APP_TRA_APP_URI,
  outerIamApiUrl: VUE_APP_OUTER_IAM_API_URI,
  contentApiUrl: VUE_APP_CONTENT_API_URI,
  publicContentApiUrl: VUE_APP_PUBLIC_CONTENT_API_URI,
  publicDevContentApiUrl: VUE_APP_DEV_PUBLIC_CONTENT_API_URI,
  redirectUri: VUE_APP_REDIRECT_URI,
  authorizationServer: VUE_APP_AUTHORIZATION_SERVER,
  helpUrl: VUE_APP_HELP_URI,
  passwordResetUrl: VUE_APP_PASSWORD_URI,
  privacyPolicyUrl: VUE_APP_PRIVACY_POLICY_URI,
  fallbackLanguage: VUE_APP_FALLBACK_LANGUAGE,
  adEmailSuffix: VUE_APP_AD_EMAIL_SUFFIX,
  oktaHostedUrl: VUE_APP_OKTA_HOSTED_URI,
  oktaGeithHostedUrl: VUE_APP_OKTA_GEITH_HOSTED_URI,
  oktaHostedAuthorizationServer: VUE_APP_OKTA_HOSTED_AUTHORIZATION_SERVER,
  passportAuroraApiUrl: VUE_APP_PASSPORT_AURORA_API_URI,
  jwtAudClaim: VUE_APP_JWT_AUD_CLAIM,
  ngpassportApiUrl: VUE_APP_NG_PASSPORT_URI,
  ngpassportDevApiUrl: VUE_APP_DEV_NG_PASSPORT_URI,
  brand: VUE_APP_BRAND,
  bobcatClientId: VUE_APP_BOBCAT_CLIENT_ID,
  geithClientId: VUE_APP_GEITH_CLIENT_ID,
  dsaUrl: VUE_APP_DSA_URL,
  tokenManagerKey: VUE_APP_TOKENMANAGER_KEY
}
