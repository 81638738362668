import axios from 'axios'
import apiUrls from '@/api/apiUrls'

export async function fetchAvailableLanguages(){
  try {
    const response = await axios.get(apiUrls.CONTENT_LANGUAGES)
    return response.data
  } catch (error) {
    window.console.warn('Failed to fetch languages' + error)
  }
}
